import Vue from 'vue';
import { weekdaysShort, months } from 'moment';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import {
  ConfigProgrammatic,
  Progress,
  Dialog,
  Modal,
  Icon,
  Datetimepicker,
  Timepicker,
  Field,
  Input,
  Select,
  Button,
  Radio,
  Table,
  Checkbox,
  Notification,
  Loading,
  Sidebar,
  Menu,
  Snackbar,
  Taginput,
  Tooltip,
  Autocomplete,
  Tag,
  Dropdown,
  Steps,
} from 'buefy';

import Datepicker from 'buefy/src/components/datepicker/Datepicker';

Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.use(Progress);
Vue.use(Icon);
Vue.use(Dialog);
Vue.use(Modal);
Vue.use(Datetimepicker);
Vue.use({
  install: (Vue) => Vue.component('by-datepicker', Datepicker),
});
Vue.use(Timepicker);
Vue.use(Field);
Vue.use(Input);
Vue.use(Select);
Vue.use(Radio);
Vue.use(Button);
Vue.use(Table);
Vue.use(Checkbox);
Vue.use(Notification);
Vue.use(Loading);
Vue.use(Sidebar);
Vue.use(Menu);
Vue.use(Snackbar);
Vue.use(Taginput);
Vue.use(Tooltip);
Vue.use(Autocomplete);
Vue.use(Tag);
Vue.use(Dropdown);
Vue.use(Steps);

ConfigProgrammatic.setOptions({
  defaultLocale: 'pt-BR',
  defaultIconComponent: 'font-awesome-icon',
  defaultIconPack: 'fas',
  defaultDayNames: weekdaysShort().map(
    (el) => `${el[0].toUpperCase()}${el.slice(1)}`
  ),
  defaultMonthNames: months().map(
    (el) => `${el[0].toUpperCase()}${el.slice(1)}`
  ),
  defaultSnackbarDuration: 3000,
  defaultSnackbarPosition: 'is-bottom-left',
  defaultDialogCancelText: 'Cancelar',
  defaultTooltipType: 'is-dark',
  defaultModalCanCancel: ['escape', 'button'],
});
