import Api from './api.service';

const ENDPOINT = 'lawyers';

export default {
  put: (data, id) => Api.put(`${ENDPOINT}/${id}`, data),
  patch: (data, id) => Api.patch(`${ENDPOINT}/${id}`, data),
  getId: (id) => Api.get(`${ENDPOINT}/${id}`),
  refresh: () => Api.get(`${ENDPOINT}/refresh`),
  setPhoto: (doctorId, file, type, junoFile = 0) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('type', type);
    formData.append('juno_file', junoFile);

    return Api.post(`${ENDPOINT}/${doctorId}/set-photo`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Accept: 'application/json',
      },
    });
  },

  updateOnline: (on_line, doctorId) =>
    Api.put(`${ENDPOINT}/${doctorId}/online`, { on_line }),

  // getAppointmentInfo: (params) => Api.get('appointments', { params }),
  getAppointmentInfo() {
    return Api.get(`appointments-types`);
  },

  getJunoDocumentsStatus(doctorId) {
    return Api.get(`${ENDPOINT}/${doctorId}/juno-documents-status`);
  },

  getPagarmeAccountStatus(doctorId) {
    return Api.get(`${ENDPOINT}/${doctorId}/pagarme-account-status`);
  },

  getDoctorTransactions(params = {}) {
    return Api.get(`${ENDPOINT}-transactions`, { params });
  },

  getBalanceInfo() {
    return Api.get(`${ENDPOINT}/balance-info`);
  },

  transferBalance(doctorId, amount) {
    return Api.post(`${ENDPOINT}/transfer-balance/${doctorId}`, { amount });
  },

  getCertificate() {
    return Api.get(`${ENDPOINT}/digital-certificate`);
  },

  storeCertificate(data) {
    const formData = new FormData();

    formData.append('file', data.file ?? '');
    formData.append('password', data.password ?? '');

    return Api.post(`${ENDPOINT}/digital-certificate`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Accept: 'application/json',
      },
    });
  },

  deleteCertificate(certificateId) {
    return Api.delete(`${ENDPOINT}/${certificateId}/digital-certificate`);
  },
};
