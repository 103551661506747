<template>
  <b-field
    :label="label"
    :type="inputType"
    :message="errorMessage"
    :class="{ required: isRequired }"
  >
    <b-taginput
      ref="tagInput"
      @typing="getFiltered"
      @input="onInput"
      v-model="selected"
      :data="filtered"
      :maxtags="max"
      :placeholder="placeholder"
      type="is-secondary"
      autocomplete
      :disabled="disabled"
    >
      <template slot-scope="props">
        {{ props.option.name }}
      </template>

      <template slot="selected" slot-scope="props">
        <b-tag
          v-for="(tag, index) in props.tags"
          :key="index"
          :id="tag.id.toString()"
          :tabstop="false"
          @remove="$refs.tagInput.removeTag(tag)"
          type="is-dark"
          rounded
          ellipsis
          :closable="!disabled"
          :no-remove="tagRemove"
        >
          {{ tag.name }}
        </b-tag>
      </template>
    </b-taginput>
  </b-field>
</template>

<script>
export default {
  props: {
    label: String,
    placeholder: String,
    isRequired: Boolean,
    disabled: {
      type: Boolean,
      default: false,
    },
    column: {
      type: String,
      default: 'name',
    },
    options: {
      type: Array,
      default: () => [],
    },
    selecteds: {
      type: Array,
      default: () => [],
    },
    max: {
      type: Number,
      default: 5,
    },
    tagRemove: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Array,
      default: () => [],
    },
  },
  model: {
    prop: 'model',
    event: 'selected',
  },
  data: () => ({
    selected: [],
    filtered: [],
  }),
  computed: {
    inputType() {
      if (this.errors && this.errors.length) return 'is-danger';
      return null;
    },
    errorMessage() {
      if (!this.errors || !this.errors.length) return null;
      return this.errors[0];
    },
  },
  mounted() {
    this.selected = [...this.selecteds];
  },

  methods: {
    onInput(selected) {
      this.$emit(
        'selected',
        selected.map((tag) => tag.id)
      );
    },

    getFiltered(text) {
      this.filtered = this.options.filter((option) => {
        return (
          option[this.column]
            .toString()
            .toLowerCase()
            .includes(text.toLowerCase()) &&
          !this.selected.find((el) => el.id === option.id)
        );
      });
    },
  },
  
};
</script>
