<template>
  <div class="financial-view">
    <app-header></app-header>

    <section>
      <div class="card">
        <!-- <b-loading :active.sync="isLoading" :is-full-page="false"></b-loading> -->

        <div class="card-content">
          <div class="row col-12 mt-2">
            <div class="col-11 mx-sm-auto mx-md-0 col-xl-4 col-md-5">
              <app-input
                readonly
                type="text"
                label="Saldo"
                icon="dollar-sign"
                v-b-tooltip.hover="'Saldo à liberar + Saldo transferível'"
                :value="fixedValue('balance')"
              ></app-input>
            </div>

            <div class="col-11 mx-sm-auto mx-md-0 col-xl-4 col-md-5">
              <app-input
                readonly
                type="text"
                label="Saldo a liberar"
                icon="dollar-sign"
                :value="fixedValue('withheld')"
              ></app-input>
            </div>

            <div class="col-11 mx-sm-auto mx-md-0 col-xl-4 col-md-5">
              <app-input
                readonly
                type="text"
                label="Saldo transferível"
                icon="dollar-sign"
                :value="fixedValue('transferable')"
              ></app-input>
            </div>
          </div>

          <div class="row col-12 mt-4 mb-5">
            <div class="mx-sm-auto mx-md-0 ml-md-2" style="width: 250px">
              <b-button
                @click="onTransferClick"
                :disabled="isLoading"
                type="is-primary"
                class="button-rounded btn-success border-0 remove-focus"
                block
              >
                Transferir para conta bancária
              </b-button>
            </div>
          </div>

          <div class="col-12 mt-4">
            <div class="card">
              <div class="card-header">
                <div class="card-header-title">Histórico de transferências</div>
              </div>

              <div class="card-content">
                <b-table
                  id="table-transactions"
                  class="table-custom"
                  :fields="fields"
                  :items="items"
                  :per-page="perPage"
                  :total="total"
                  :busy="isLoading"
                  thead-tr-class="thead-tr-custom"
                  sticky-header="450px"
                  no-provider-sorting
                  no-provider-filtering
                  small
                  responsive
                  show-empty
                  hover
                >
                  <template #cell(amount)="data">
                    {{ data.value | currency }}
                  </template>

                  <template #cell(created_at)="data">
                    {{ data.value | date('DD/MM/YYYY') }}
                  </template>

                  <!-- AJUSTA o tamanho para o tamanho do conteúdo sem necessidade de definir manualmente -->
                  <template #table-colgroup="scope">
                    <col
                      v-for="field in scope.fields"
                      :key="field.key"
                      style="
                        min-width: fit-content;
                        max-width: auto;
                        text-align: center;
                      "
                    />
                  </template>

                  <template #table-busy>
                    <section class="section">
                      <div class="text-center my-2">
                        <b-spinner class="align-middle mr-2" small></b-spinner>
                        <strong style="margin-left: 2px">Carregando...</strong>
                      </div>
                    </section>
                  </template>

                  <!-- Conteúdo vazio -->
                  <template slot="empty">
                    <section class="section">
                      <div
                        class="d-flex justify-content-center align-items-ter"
                      >
                        <p class="mt-4">Nenhum registro encontrado.</p>
                      </div>
                    </section>
                  </template>
                </b-table>
              </div>

              <!-- PAGINATION -->
              <div class="card-content pt-2">
                <b-col class="d-flex justify-content-end">
                  <b-col
                    class="col-sm-12 col-md-auto d-flex align-items-center mr-sm-0 mr-md-2"
                  >
                    <p class="p-0 m-0">
                      Total de registro:
                      <strong>{{ transactions.length }}</strong>
                    </p>
                  </b-col>

                  <b-col class="col-sm-12 col-md-auto">
                    <b-pagination
                      class="pagination-custom"
                      page-class="pagination-custom-page"
                      next-class="pagination-custom-next"
                      prev-class="pagination-custom-prev"
                      v-model="page"
                      :per-page="perPage"
                      :total-rows="total"
                      :disabled="isLoading"
                      aria-controls="table-transactions"
                      first-number
                      last-number
                    ></b-pagination>
                  </b-col>
                </b-col>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import DoctorService from '@/services/doctor.service';
import BalanceTransfer from '@/modals/BalanceTransfer.vue';

export default {
  beforeMount() {
    this.loadBalanceInfo();
    this.loadDoctorTransactions();
  },

  data: () => ({
    isLoading: false,
    is_loading_transactions: false,
    transactions: [],
    total: 0,
    page: 1,
    perPage: 10,
    balance_info: {
      balance: null,
      withheld: null,
      transferable: null,
    },
  }),

  watch: {
    perPage() {
      this.loadDoctorTransactions();
    },

    page() {
      this.loadDoctorTransactions();
    },
  },

  computed: {
    ...mapGetters(['doctorId']),

    fields: () => [
      {
        key: 'amount',
        label: 'Valor Transferido',
        sortable: true,
      },
      {
        key: 'created_at',
        label: 'Data da Transferência',
        sortable: true,
      },
    ],

    items() {
      return this.transactions;
    },
  },

  methods: {
    onPageChange(page) {
      this.page = page;
      this.loadDoctorTransactions();
    },

    loadBalanceInfo() {
      this.isLoading = true;
      DoctorService.getBalanceInfo()
        .then(({ data }) => {
          this.balance_info = { ...data };
        })
        .finally(() => (this.isLoading = false));
    },

    loadDoctorTransactions() {
      const params = {
        page: this.page,
        perPage: this.perPage,
        doctor_id: this.$store.getters.doctorId,
      };
      this.is_loading_transactions = true;

      DoctorService.getDoctorTransactions({ ...params })
        .then(({ data }) => {
          const { total, data: items, current_page } = data;

          if (this.page <= 1) {
            this.page = current_page;
            this.total = total;
          }

          this.transactions = items;
        })
        .finally(() => (this.is_loading_transactions = false));
    },

    onTransferClick() {
      if (this.balance_info.transferable <= 0) {
        return this.$buefy.snackbar.open({
          message: 'Você não tem saldo suficiente para transferir',
          duration: 4000,
          type: 'is-warning',
          pauseOnHover: true,
        });
      }

      this.$buefy.modal.open({
        parent: this,
        component: BalanceTransfer,
        hasModalCard: true,
        trapFocus: true,
        props: {
          transferableValue: this.balance_info.transferable,
        },
        events: {
          close: (data) => data && this.transferBalance(data.value),
        },
      });
    },

    transferBalance(value) {
      this.isLoading = true;

      DoctorService.transferBalance(this.doctorId, value)
        .then(({ data }) => {
          this.$buefy.snackbar.open(data.message);
          this.loadBalanceInfo();
          this.loadDoctorTransactions();
        })
        .catch(({ response }) => {
          if ([400, 422, 420].includes(response.status)) {
            this.$buefy.snackbar.open({
              message: response.data.message ?? response.data.errors,
              type: 'is-danger',
              duration: 5000,
              pauseOnHover: true,
            });
          }
        })
        .finally(() => (this.isLoading = false));
    },

    fixedValue(key) {
      return parseFloat(
        this.balance_info[key] ? this.balance_info[key] : (0).toFixed(2)
      ).toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
        minimumFractionDigits: 2,
      });
    },
  },

  currency(value) {
    return parseFloat(value).toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
      minimumFractionDigits: 2,
    });
  },
};
</script>

<style lang="scss">
.thead-tr-custom {
  & th {
    padding: 0 10px;
  }
}
</style>
