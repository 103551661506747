<template>
  <div class="time-schedule-modal">
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Novo Horário</p>

        <b-icon
          class="question-help"
          icon="question-circle-fill"
          v-b-tooltip.hover="
            'Defina o intervalo de horário e os dias da semana em que deseja realizar os atendimentos (pode ser vários no mesmo dia, desde que não haja conflitos de horários), em seguida, o AdvEasy irá criar várias agendas dentro deste intervalo, conforme o tempo pré-defindo para cada consultoria! Ah, o período indica até quando você vai querer que a agenda fique disponível'
          "
        ></b-icon>
      </header>

      <section class="modal-card-body">
        <div class="row">
          <div class="col-4">
            <app-input
              type="tel"
              is-required
              label="Horário Inicial"
              v-mask="'##:##'"
              placeholder="Informe o horário inicial"
              v-model="start"
              :errors="errors.start"
            ></app-input>
          </div>

          <div class="col-4 offset-1">
            <app-input
              is-required
              label="Horário Final"
              v-mask="'##:##'"
              placeholder="Informe o horário final"
              v-model="end"
              :errors="errors.end"
            ></app-input>
          </div>
        </div>

        <div class="row mt-4">
          <div class="col-6">
            <weekdays-selector v-model="days"></weekdays-selector>
          </div>
        </div>

        <div class="row mt-4">
          <div class="col-4">
            <b-field label="Período Inicial" :message="errors.date_start">
              <app-input
                class="input-custom"
                v-model.trim="date_start"
                placeholder="Informe uma data..."
                v-mask="'##/##/####'"
              ></app-input>
            </b-field>
          </div>

          <div class="col-4 offset-1">
            <b-field label="Período Final" :message="errors.date_end">
              <app-input
                class="input-custom"
                v-model.trim="date_end"
                placeholder="Informe uma data..."
                v-mask="'##/##/####'"
              ></app-input>
            </b-field>
          </div>
        </div>

        <div class="row col-12 mt-5">
          <b-alert
            class="mx-auto"
            style="font-size: 0.9rem"
            v-if="errors.info"
            show
            variant="danger"
            dismissible
          >
            {{ errors.info }}
          </b-alert>
        </div>
      </section>

      <footer class="modal-card-foot is-right">
        <b-button
          @click="$emit('close')"
          class="button-rounded remove-focus btn-secondary mr-2 px-3"
          :disabled="isSaving"
        >
          Fechar
        </b-button>

        <b-button
          @click="save()"
          :loading="isSaving"
          :disabled="isDisabled || isSaving"
          class="button-rounded remove-focus btn-success border-0 px-3"
        >
          Salvar
        </b-button>
      </footer>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import SchedulesService from '@/services/schedules.service';
import DoctorService from '@/services/doctor.service';

import WeekdaysSelector from '@/components/WeekdaysSelector';
/* import ScheduleStandartTimeSelector from '@/components/ScheduleStandartTimeSelector'; */

import DoctorAccountStage from '@/enums/DoctorAccountStage';

import moment from 'moment';

export default {
  components: {
    WeekdaysSelector,
    /*  'time-selector': ScheduleStandartTimeSelector, */
  },

  data: () => ({
    isSaving: false,
    start: null,
    end: null,
    appointments_types: [],
    complete: false,
    startError: '',
    endError: '',
    days: [],
    timeRangeError: null,
    endShowError: false,
    date_start: null,
    date_end: null,
    errors: {},
  }),

  beforeMount() {
    this.loadDoctor();
  },

  computed: {
    ...mapGetters(['accountStage', 'doctorId']),

    isDisabled() {
      const validForm = [this.start, this.end, this.date_start, this.date_end];
      return validForm.some((item) => item === null);
    },
  },

  watch: {
    start(newValue) {
      this.errors.start = null;
      this.errors.end = null;

      if (!newValue) return;

      const [hours, minutes] = newValue.split(':');

      if (!hours || !minutes) return;
    },

    end(newValue) {
      this.errors.start = null;
      this.errors.end = null;

      if (!newValue) return;

      const [hours, minutes] = newValue.split(':');

      if (!hours || !minutes) return;

      if (this.start > newValue) {
        this.errors.end = ['Horário não pode ser menor que o de início!'];
      }

      this.validHours(hours, minutes);
    },
  },

  methods: {
    loadDoctor() {
      DoctorService.getId(this.$store.getters.doctorId).then(
        ({ data: doctor }) => {
          this.complete = doctor.account_stage === DoctorAccountStage.COMPLETE;

          this.appointments_types = doctor.appointment_types.map(
            ({ id, name }) => ({ id, name })
          );
        }
      );
    },

    save() {
      const { start, end, days, appointments_types, date_start, date_end } =
        this;

      this.errors = {};

      const [startHour, startMinutes] = start.split(':');
      const [endHour, endMinutes] = end.split(':');

      const promises = days.map((weekday) => {
        const start = moment({
          day: new Date().getDay(),
          month: new Date().getMonth(),
          year: new Date().getFullYear(),
          hour: startHour,
          minutes: startMinutes,
        }).format('HH:mm');

        const end = moment({
          day: new Date().getDay(),
          month: new Date().getMonth(),
          year: new Date().getFullYear(),
          hour: endHour,
          minutes: endMinutes,
        }).format('HH:mm');

        const appointment_types = appointments_types.map((x) => x.id);

        return SchedulesService.storeAvailability({
          start,
          end,
          weekday,
          date_start: moment(
            date_start?.split('/').reverse().join('-')
          ).toISOString(),

          date_end: moment(
            date_end?.split('/').reverse().join('-')
          ).toISOString(),
          appointment_types,
          doctor_id: this.doctorId,
        });
      });

      this.isSaving = true;

      Promise.all(promises)
        .then((responses) => {
          const times = responses.map(({ data }) => data);
          this.$buefy.snackbar.open('Horários cadastrados com sucesso!');
          this.$emit('close', times);
        })
        .catch(({ data, status }) => {
          if (status == 400) {
            let errors_message = '';
            const errors = Object.values(data?.message ?? {});

            if (errors && Array.isArray(errors)) {
              errors.forEach((error) => {
                errors_message += `${error[0]} \n`;
              });

              this.errors = {
                info: errors_message,
              };
            }
          }

          if (!('info' in this.errors)) {
            this.errors = {
              info: 'Por favor, Verifique se não há sobreposição entre os períodos das datas ou horas.',
            };
          }
        })
        .finally(() => (this.isSaving = false));
    },

    canSetStandartTime() {
      return this.start && this.end;
    },

    validHours(hours, minutes) {
      if (Number(hours) < 0 || Number(hours) > 23)
        this.errors.start = ['Horário inválido!'];
      else if (Number(minutes) < 0 || Number(minutes) > 59)
        this.errors.start = ['Horário inválido!'];
      else this.errors.start = [];
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-card {
  width: 850px;
  height: 850px;

  display: flex;
  justify-content: space-between;
}

#schedule-standard-time-error {
  color: red;
}
</style>
