<template>
  <div class="loading-view">
    <div class="col-12 col-xl-5 col-md-7 progress-container">
      <b-col class="col-12 mb-2">
        <b-img :src="require('@/assets/img/logo_oficial.png')"/>
      </b-col>

      <b-spinner class="loading-spinner"></b-spinner>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
div.loading-view {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;

  .progress-container {
    width: 200px;
    text-align: center;
  }

  .loading-spinner {
    color: var(--color-primary);
    width: 3rem;
    height: 3rem;
  }
}
</style>
