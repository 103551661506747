<template>
  <div class="register-page col-11 col-lg-10 p-1 pb-3 py-md-5 px-md-5 mx-auto">
    <div class="register-page__form-container custom-scroll">
      <app-header goes-back class="app-header" title="Cadastrar-se">
      </app-header>

      <form @submit="(event) => event.preventDefault()">
        <div class="row mt-2">
          <div class="col-6">
            <b-field
              label="Foto pessoal"
              :type="errors.photo ? 'is-danger' : ''"
              :message="errors.photo"
              class="required"
            >
              <div class="doctor-photo" :class="{ photo_invalid: !doctor.photo }">
                <img :src="doctorPhoto" alt="Doctor Photo" />
                <div
                  @click="onSelectPhotoClick"
                  class="select-button text-white"
                >
                  <b-icon icon="camera" class="text-white"></b-icon>
                </div>
              </div>
            </b-field>

            <app-cropper-image
              v-if="photoToCrop"
              :src="photoToCrop"
              :aspectRatio="1"
              class="cropper-image"
              @croppedImage="onCropperImage"
            >
            </app-cropper-image>

            <input
              ref="file"
              @change="onPhotoToCropChange($event, 'profile')"
              accept="image/*"
              style="display: none"
              type="file"
              class="required"
            />
          </div>
        </div>

        <div class="row mt-2">
          <div class="col-11 mx-auto col-lg-4 mx-lg-0 col-md-11">
            <app-input
              label="Nome"
              type="text"
              v-model="doctor.name"
              :errors="errors.name"
              is-required
            ></app-input>
          </div>

          <div class="col-11 mx-auto col-lg-4 mx-lg-0 col-md-11">
            <app-input
              label="CPF"
              type="text"
              v-model="doctor.cpf"
              v-mask="'###.###.###-##'"
              :errors="errors.cpf"
              @blur="testaCPF"
              is-required
            >
            </app-input>
          </div>

          <div class="col-11 mx-auto col-lg-4 mx-lg-0 col-md-11">
            <app-input
              label="E-mail"
              type="email"
              v-model.trim="doctor.email"
              autocomplete="email"
              :errors="errors.email"
              is-required
            ></app-input>
          </div>
        </div>

        <div class="row mt-2">
          <div class="col-11 mx-auto col-lg-3 mx-lg-0">
            <b-field class="required" label="Gênero">
              <b-select
                class="select-custom"
                v-model="doctor.gender"
                placeholder="Selecionar"
                expanded
              >
                <option
                  v-for="gender in genders"
                  :value="gender.char"
                  :key="gender.char"
                >
                  {{ gender.name }}
                </option>
              </b-select>
            </b-field>
          </div>

          <div class="col-11 mx-auto col-lg-3 mx-lg-0">
            <b-field
              label="Data de nascimento"
              class="input-custom required text-danger"
              :message="errors.birthday"
            >
              <app-input
                class="input-custom"
                v-model.trim="doctor.birthday"
                placeholder="Digite a data..."
                v-mask="'##/##/####'"
              ></app-input>
            </b-field>
          </div>

          <div class="col-11 mx-auto col-lg-3 mx-lg-0">
            <app-input
              label="Telefone Fixo (opcional)"
              type="text"
              v-model="doctor.phone"
              v-mask="'(##) ####-####'"
              :errors="errors.phone"
            ></app-input>
          </div>

          <div class="col-11 mx-auto col-lg-3 mx-lg-0">
            <app-input
              label="Celular"
              type="text"
              v-model="doctor.cellphone"
              v-mask="'(##) #####-####'"
              :errors="errors.cellphone"
              is-required
            ></app-input>
          </div>
        </div>

        <div class="row mt-2">
          <div class="col-11 mx-auto col-lg-2 mx-lg-0 col-md-11">
            <app-input
              label="Cep"
              type="text"
              v-model="doctor.cep"
              v-mask="'#####-###'"
              @keyup="getCepInfo"
              :errors="errors.cep"
            >
            </app-input>
          </div>

          <div class="col-11 mx-auto col-lg-3 mx-lg-0 col-md-11">
            <app-input
              label="Endereço"
              type="text"
              v-model.trim="doctor.street"
              :errors="errors.street"
              :disabled="isLoadingCep"
              is-required
            >
            </app-input>
          </div>

          <div class="col-11 mx-auto col-lg-2 mx-lg-0 col-md-11">
            <app-input
              label="Número"
              type="text"
              v-model="doctor.number"
              @keypress="onlyNumbers"
              :errors="errors.number"
              is-required
            >
            </app-input>
          </div>

          <div class="col-11 mx-auto col-lg-3 mx-lg-0 col-md-11">
            <app-input
              label="Bairro"
              type="text"
              v-model.trim="doctor.neighborhood"
              :errors="errors.neighborhood"
              :disabled="isLoadingCep"
              is-required
            >
            </app-input>
          </div>
        </div>

        <div class="row mt-2">
          <div class="col-11 mx-auto col-lg-4 mx-lg-0 col-md-11">
            <app-input
              label="Cidade"
              type="text"
              v-model.trim="doctor.city_name"
              :errors="errors.city_name"
              :disabled="isLoadingCep"
              is-required
            ></app-input>
          </div>

          <div class="col-11 mx-auto col-lg-4 mx-lg-0 col-md-11">
            <app-input
              label="Estado"
              type="text"
              v-model.trim="doctor.state_name"
              :errors="errors.state_name"
              :disabled="isLoadingCep"
              is-required
            ></app-input>
          </div>

          <div class="col-11 mx-auto col-lg-4 mx-lg-0 col-md-11">
            <app-input
              label="Complemento"
              type="text"
              v-model.trim="doctor.complement"
              :errors="errors.complement"
            ></app-input>
          </div>
        </div>

        <div class="row mt-2">
          <div class="col-11 mx-sm-auto col-lg-4 mx-lg-0 col-md-11">
            <b-field
              label="Foto da carteirinha OAB (frente) *"
              class="text-danger"
              :message="errors.crm_photo_front"
            >
              <app-file-picker
                accept="image/*"
                class="text-truncate required text-danger"
                v-model="doctor.crm_photo_front"
                @fileSelected="onCrmPhotoSelected('crm_photo_front', $event)"
                @fileDeleted="() => (this.doctor.crm_photo_front = '')"
              >
              </app-file-picker>

              <b-col
                v-if="doctor.crm_photo_front"
                class="col-12 d-flex overflow-hidden p-0"
                :style="{ height: '220px' }"
              >
                <b-loading
                  :active.sync="crmPhotoIsLoading"
                  :is-full-page="false"
                ></b-loading>
                <b-img
                  fluid
                  :src="doctor.crm_photo_front"
                  alt="Imagem da carteirinha OAB"
                ></b-img>
              </b-col>
            </b-field>
          </div>

          <div class="col-11 mx-sm-auto col-lg-4 mx-lg-0 col-md-11">
            <b-field
              label="Foto da carteirinha OAB (verso) *"
              class="text-danger"
              :message="errors.crm_photo_verse"
            >
              <app-file-picker
                accept="image/*"
                class="text-truncate required text-danger"
                v-model="doctor.crm_photo_verse"
                @fileSelected="onCrmPhotoSelected('crm_photo_verse', $event)"
                @fileDeleted="() => (this.doctor.crm_photo_verse = '')"
              >
              </app-file-picker>

              <b-col
                v-if="doctor.crm_photo_verse"
                class="col-12 d-flex overflow-hidden p-0"
                :style="{ height: '220px' }"
              >
                <b-loading
                  :active.sync="crmPhotoIsLoading"
                  :is-full-page="false"
                ></b-loading>
                <b-img
                  fluid
                  :src="doctor.crm_photo_verse"
                  alt="Imagem da carteirinha OAB"
                ></b-img>
              </b-col>
            </b-field>
          </div>
        </div>

        <div class="row mt-2">
          <div class="col-11 mx-sm-auto col-lg-4 mx-lg-0 col-md-11">
            <app-input
              label="OAB"
              type="text"
              v-model="doctor.crm"
              @keypress="onlyNumbers"
              :errors="errors.crm"
              is-required
            ></app-input>
          </div>

          <div class="col-11 mx-sm-auto col-lg-4 mx-lg-0 col-md-11">
            <b-field
              label="Estado da OAB"
              class="required text-danger"
              :message="errors.crm_uf"
              is-required
            >
              <b-select
                v-model="doctor.crm_uf"
                :options="statesFormat"
                class="select-custom"
                aria-role="list"
              ></b-select>
            </b-field>
          </div>
        </div>

        <div class="row mt-2">
          <div class="col-11 mx-sm-auto col-lg-4 mx-lg-0 col-md-11">
            <tag-input
              v-model="doctor.specialties"
              label="Adicione sua especialidade"
              placeholder="Selecionar"
              :options="specialties"
              :max="3"
              :errors="errors.specialties"
              is-required
            ></tag-input>
            <b-form-text class="text-warning">
              Selecione até 3 especialidades
            </b-form-text>
          </div>
        </div>

        <div class="row mt-5">
          <b-col class="col-lg-3 col-md-6 offset-lg-9 mx-lg-0 mx-sm-auto">
            <b-button
              type="submit"
              class="button-rounded btn-success remove-focus border-0"
              :disabled="isRequest"
              @click="onSubmit"
              block
            >
              <b-spinner
                v-if="isLoading"
                class="mx-auto"
                label="Spinning"
                small
              ></b-spinner>
              <span v-else>Cadastrar</span>
            </b-button>
          </b-col>
        </div>

        <div class="row mt-5">
          <div class="col-12 mt-2 text-center">
            <router-link
              :to="{ name: 'login' }"
              :style="{ color: 'var(--text-color)' }"
            >
              Já tem cadastro?
            </router-link>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import debounce from 'lodash/debounce';

import FileMixin from '@/mixins/FileMixin';
import AuthService from '@/services/auth.service';
import CepService from '@/services/cep.service';
import SpecialtiesService from '@/services/specialties.service';
import StateIBGE from '@/services/state.ibge.service';
import StatesService from '@/services/states.service';
import TermsService from '@/services/terms.service';
import CropperImage from '@/components/CropperImage.vue';
import MASKS from '@/constants/masks.constant';
import TagInput from '@/components/inputs/TagInput.vue';
import moment from 'moment';

const vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty('--vh', `${vh}px`);

export default {
  components: {
    'app-cropper-image': CropperImage,
    'tag-input': TagInput,
  },
  mixins: [FileMixin],

  props: {
    isModal: Boolean,
  },

  computed: {
    doctorPhoto() {
      return this.doctor.photo ?? require('@/assets/img/user.png');
    },

    filteredBanks() {
      return this.banks.filter(
        (bank) =>
          bank.name.toLowerCase().indexOf(this.searchBank.toLowerCase()) >= 0
      );
    },

    specialtiesFormat() {
      return this.specialties.map(({ id, name }) => ({
        value: id,
        text: name,
      }));
    },

    statesFormat() {
      return this.states.map((state) => ({
        value: state.id,
        text: state.name,
      }));
    },
  },

  beforeMount() {
    this.isLoading = true;
    this.loadSpecialties();
    this.loadStates();
  },

  watch: {
    'doctor.specialties'() {
      if (this.doctor.specialties[0])
        SpecialtiesService.getSpecialties(this.doctor.specialties[0]).then();
    },

    'errors.birthday'() {
      return this.errors?.birthday ? this.errors.birthday[0] : '';
    },

    'errors.specialties'() {
      return this.errors?.specialties ? this.errors.specialties[0] : '';
    },
  },

  data: () => ({
    MASKS: MASKS,
    isRequest: false,
    isLoading: false,
    photoIsLoading: false,
    photoToCrop: '',
    specialties: [],
    tipo_atendimento: [],
    values: [],
    nfoto: '',
    doctorCity: null,
    states: [],
    cities: [],
    showPassword: false,
    city_id: null,
    element: null,
    rev: null,
    add: null,
    cpf: null,
    cnpj: null,
    phone: null,
    cellphone: null,
    is_loading_transactions: false,
    transactions_data: [],
    total: 0,
    page: 1,
    perPage: 10,
    errors: {},
    isLoadingCity: false,
    isLoadingBank: false,
    isLoadingCep: false,
    countLoad: 0,
    searchSpecialty: '',
    searchTipoAtendimento: '',
    doctorTipoAtendimento: [],
    photoSelected: false,
    documentPhotoSelected: false,
    showAccountComplementNumber: false,
    documentPhotoIsLoading: false,
    crmPhotoIsLoading: false,
    PhotoIsLoading: false,
    isCreate: false,
    doctor: {
      name: null,
      email: null,
      cpf: null,
      gender: null,
      photo: null,
      phone: null,
      cellphone: null,
      crm: null,
      crm_uf: null,
      crm_photo_front: null,
      crm_photo_verse: null,
      photoSelected: false,
      on_line: 0,
      term: null,
      photoToCrop: '',
      promises: [],
      specialties: [],
      searchSpecialty: '',
      birthday: null,
      cep: '',
      street: '',
      number: '',
      neighborhood: '',
      city_name: '',
      state_name: '',
      complement: '',
    },
    isPaginated: false,
    isPaginationSimple: false,
    isPaginationRounded: false,
    paginationPosition: 'both',
    defaultSortDirection: 'asc',
    sortIcon: 'arrow-down',
    sortIconSize: 'is-small',
    currentPage: 1,
    pageOptions: [10, 20, 40, 80],
    password_confirmation: null,
    genders: [
      { char: 'm', name: 'Masculino' },
      { char: 'f', name: 'Feminino' },
    ],
    tp: [],
    tps: [],
    tipos_atendimento: [
      { id: '1 - Chat' },
      { id: '2 - Ligação' },
      { id: '3 - Vídeo Chamada' },
    ],
    validations: {
      photo: {
        message: 'A foto é obrigatória',
        removeMask: false,
        required: true,
      },
      name: {
        message: 'Campo nome é obrigatório',
        removeMask: false,
        required: true,
      },
      cpf: {
        message: 'Campo cpf é obrigatório',
        removeMask: true,
        required: true,
        length: 11,
      },
      email: {
        message: 'Campo email é obrigatório',
        removeMask: false,
        required: true,
      },
      gender: {
        message: 'Campo gênero é obrigatório',
        removeMask: false,
        required: true,
      },
      birthday: {
        message: 'Campo data de nascimento é obrigatória',
        removeMask: false,
        required: true,
      },
      phone: {
        message: '',
        removeMask: true,
        required: false,
        length: 10,
      },
      cellphone: {
        message: 'Campo celular é obrigatório',
        removeMask: true,
        required: true,
        length: 11,
      },
      cep: {
        message: '',
        length: 8,
        removeMask: true,
        required: false,
      },
      street: {
        message: 'Campo endereço é obrigatório',
        removeMask: false,
        required: true,
      },
      number: {
        message: 'Campo número é obrigatório',
        removeMask: false,
        required: true,
      },
      neighborhood: {
        message: 'Campo bairro é obrigatório',
        removeMask: false,
        required: true,
      },
      city_name: {
        message: 'Campo cidade é obrigatória',
        removeMask: false,
        required: true,
      },
      state_name: {
        message: 'Campo estado é obrigatório',
        removeMask: false,
        required: true,
      },
      crm_photo_front: {
        message: 'A foto da carteirinha da OAB é obrigatória',
        removeMask: false,
        required: true,
      },
      crm_photo_verse: {
        message: 'A foto da carteirinha da OAB é obrigatória',
        removeMask: false,
        required: true,
      },
      crm: {
        message: 'OAB é obrigatória',
        removeMask: false,
        required: true,
      },
      crm_uf: {
        message: 'Campo estado da OAB é obrigatória',
        removeMask: false,
        required: true,
      },
      specialties: {
        message: 'Campo especialidade é obrigatória',
        removeMask: false,
        required: true,
        max: 3,
        min: 1,
      },
    },
  }),

  methods: {
    loadStates() {
      this.isLoading = true;
      StatesService.get('/estados', { orderBy: 'nome' })
        .then((response) => {
          this.states = response.data;
        })
        .catch(({ response }) => {
          this.$buefy.snackbar.open(
            response.data.message ||
              'Erro ao tentar carregar os estados. Tente novamente mais tarde.'
          );
        })
        .finally(() => (this.isLoading = false));
    },

    loadSpecialties() {
      SpecialtiesService.getSpecialties().then(({ data }) => {
        this.specialties = data;
      });
    },

    loadTerm() {
      TermsService.get().then(({ data }) => {
        this.term = data;
      });
    },

    onSelect(values) {
      if (values) {
        this.tipo_atendimento.push(
          Object.values(values).toString().substr(0, 1)
        );
      }
    },

    onRemove(values) {
      if (values) {
        const val = Object.values(values).toString().substring(0, 1);
        this.tipo_atendimento.splice(this.tipo_atendimento.indexOf(val), 1);
      }
    },

    onClearDate() {
      this.doctor.birthday = null;
    },

    changePasswordType() {
      this.showPassword = !this.showPassword;
    },

    testaCPF() {
      if (!this.doctor.cpf) return;

      let cpf = this.doctor.cpf.replace(/[^\d]+/g, '').trim();

      const regex = new RegExp(
        `^(1{11}|2{11}|3{11}|4{1}|5{11}|6{11}|7{11}|8{11}|9{11})$`
      );

      if (cpf?.length < 11 || regex.test(cpf)) {
        this.$buefy.snackbar.open({
          message: 'Cpf inválido',
          type: 'is-danger',
          duration: 5000,
          pauseOnHover: true,
        });
        this.doctor.cpf = '';
        return;
      }
    },

    onSubmit() {
      let invalid = false;
      const errors = {};

      // atribuindo um objeto não referenciado
      const form = { ...this.doctor };

      for (let key in this.validations) {
        invalid = false;

        if (this.validations[key]?.removeMask) {
          let value = form[key];
          form[key] = value?.replaceAll(/[^\w\d]+/g, '');
        }

        if (
          this.validations[key]?.required &&
          (form[key] === null || form[key]?.length === 0)
        ) {
          invalid = true;
        }

        if (
          'length' in this.validations[key] &&
          form[key]?.length !== this.validations[key]?.length &&
          (this.validations[key].required ||
            (!this.validations[key].required && form[key]?.length > 0))
        ) {
          invalid = true;
        }

        if (
          ('max' in this.validations[key] &&
            form[key]?.length > this.validations[key].max) ||
          ('min' in this.validations[key] &&
            form[key]?.length < this.validations[key].min)
        ) {
          invalid = true;
        }

        if (invalid) errors[key] = [this.validations[key].message];
      }

      if (Reflect.ownKeys(errors).length > 0) {
        this.errors = errors;

        return this.$buefy.snackbar.open({
          message: 'Preencha os campos obrigatórios',
          type: 'is-danger',
          duration: 5000,
          pauseOnHover: true,
        });
      }

      if (this.doctor.password !== this.doctor.password_confirmation)
        return this.$buefy.snackbar.open({
          message: 'As senhas não conferem',
          type: 'is-danger',
          duration: 5000,
          pauseOnHover: true,
        });

      this.save();
    },

    async save() {
      const data = { ...this.doctor };

      for (let key in this.validations) {
        if (this.validations[key].removeMask) {
          let value = data[key];
          data[key] = value?.replaceAll(/[^\w\d]+/g, '');
        }
      }

      this.isLoading = true;
      this.isRequest = true;
      this.errors = {};

      data.birthday = moment(data.birthday.split('/').reverse().join('-')).toISOString();

      console.log(data)

      await AuthService.store(data)
        .then(() => {
          this.$buefy.snackbar.open({
            message:
              'Cadastro realizado com sucesso, favor aguardar o nosso contato, obrigado.',
            duration: 10000,
          });
          this.$router.push({ name: 'login' });
        })
        .catch(({ response }) => {
          const { status, data } = response;

          if ([400, 422].includes(status)) {
            data?.errors && (this.errors = data.errors);

            this.$buefy.snackbar.open({
              message:
                data.message ?? 'Por favor, verifique os dados informados',
              type: 'is-danger',
              duration: 5000,
              pauseOnHover: true,
            });
          } else
            this.$buefy.snackbar.open(
              data.message ??
                'Erro ao tentar realizar o cadastro. Tente novamente mais tarde.'
            );
        })
        .finally(() => {
          this.isLoading = false;
          this.isRequest = false;
        });
    },

    onlyNumbers(event) {
      if (event.keyCode < 48 || event.keyCode > 57) event.preventDefault();
    },

    getCepInfo: debounce(function (event) {
      let cep = event.target?.value?.replace(/\D/g, '');

      if (['', undefined, null].includes(cep) || cep?.length < 8) return;

      this.isLoadingCep = true;

      CepService.getViaCep(cep)
        .then(async ({ data }) => {
          let uf;

          if (data?.uf) {
            uf = await StateIBGE.get(`/estados/${data.uf}`, {
              orderBy: 'nome',
            });
          }

          this.doctor = {
            ...this.doctor,
            street: data.logradouro,
            neighborhood: data.bairro,
            city_name: data.localidade,
            state_name: uf.data?.nome,
          };
        })
        .catch(({ status }) => {
          if (status != 200) {
            this.$buefy.snackbar.open('Cep não localizado');
          }
        })
        .finally(() => (this.isLoadingCep = false));
    }, 300),

    onFileDelete() {
      this.doctor.photo = '';
    },

    onCrmPhotoSelected(key, value) {
      this.crmPhotoIsLoading = true;
      this.fileToBase64(value).then((base64) => (this.doctor[key] = base64));
      this.crmPhotoIsLoading = false;
    },

    onCropperImage(value) {
      this.photoIsLoading = true;
      this.fileToBase64(value).then((base64) => (this.doctor.photo = base64));
      this.photoSelected = true;
      this.photoToCrop = '';
      this.photoIsLoading = false;
    },

    async onPhotoToCropChange(event, flag) {
      const [file] = event.target.files;

      if (flag == 'profile') {
        this.photoToCrop = await this.fileToBase64(file);
      }
    },

    onSelectPhotoClick() {
      if (this.photoToCrop) this.photoToCrop = null;
      this.$refs.file.click();
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
.dropdownOnError {
  border-color: #dc3545;
}

.register-page {
  height: 100%;
}

.doctor-photo {
  width: 130px;
  height: 130px;
  border-radius: 100%;

  &.photo_invalid {
    border: 2px solid #ff3838;
  }

  img {
    width: 100%;
    object-fit: cover;
    border-radius: 50%;
  }

  .select-button {
    width: 35px;
    height: 35px;

    border: 2px solid #fff;
    border-radius: 50%;

    display: flex;
    align-items: center;
    justify-content: center;

    background: var(--color-secondary);

    position: relative;
    top: -45px;
    left: 95px;

    cursor: pointer;
  }
}

.select-custom {
  &:focus,
  &:focus-visible {
    border-color: #b58e1c90 !important;
    outline: 0 !important;
    box-shadow: 0 0 0 0.2rem #b58e1c25 !important;
  }
}
</style>
