/**
 * Document
 * @url https://servicodados.ibge.gov.br/api/docs/localidades?versao=1#api
 */

import axios from 'axios';

export default {
  get(param, query = {}) {
    return axios.get(
      `https://servicodados.ibge.gov.br/api/v1/localidades/${param}`,
      {
        params: query,
      }
    );
  },
};
