<template>
  <b-field
    :label="label"
    :type="inputType"
    :message="errorMessage"
    :class="{ required: isRequired }"
  >
    <template #label v-if="tooltipLabel">
      {{ label }} &nbsp;

      <b-tooltip :label="tooltipLabel" position="is-right">
        <b-icon
          size="is-small"
          type="is-primary"
          icon="question-circle"
        ></b-icon>
      </b-tooltip>
    </template>

    <b-input-group>
      <b-input
        :type="type"
        class="input-custom"
        v-model="innerValue"
        :min="min"
        :step="step"
        :maxlength="maxlength"
        :placeholder="placeholder"
        :loading="loading"
        :autocomplete="autocomplete"
        :password-reveal="type === 'password'"
        v-bind="$attrs"
        v-on="$listeners"
      ></b-input>

      <b-input-group-append v-if="iconRight">
        <b-button
          class="button-primary button-icon-right"
          @click="$emit('onClickIconRight', $event)"
        >
          <b-icon :icon="iconRight"></b-icon>
        </b-button>
      </b-input-group-append>
    </b-input-group>
  </b-field>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    label: String,
    tooltipLabel: String,
    type: String,
    min: String,
    maxlength: String,
    step: String,
    placeholder: String,
    errors: Array,
    loading: Boolean,
    mask: Object,
    autocomplete: String,
    isRequired: Boolean,
    iconRight: String,
    inputAttrs: Object,
    value: {
      type: null,
    },
    flag: {
      type: String,
      default: 'text',
    },
  },

  computed: {
    inputType() {
      if (this.errors && this.errors?.length) return 'is-danger';
      return null;
    },
    errorMessage() {
      if (!this.errors || !this.errors?.length) return null;
      return this.errors[0];
    },
  },

  watch: {
    innerValue(newVal) {
      this.$emit('input', newVal);
    },
    value(newVal) {
      this.innerValue = newVal;
    },
  },

  mounted() {
    this.innerValue = this.value;
  },

  data: () => ({
    innerValue: null,
  }),

  methods: {
  },
};
</script>
