import Vue from 'vue';
import VueRouter from 'vue-router';

import store from '@/store';
import { isMobile } from 'mobile-device-detect';

import SCHEDULES_ROUTES from '@/router/schedules.routes';
import PRESCRIPTION_MODELS_ROUTES from '@/router/prescription-models.routes';
import SECRETARIES_ROUTES from '@/router/secretaries.routes';
import PROCEDURES_ROUTES from '@/router/procedures.routes';
import DISCOUNTS_ROUTES from '@/router/discounts.routes';
import DIGITAL_CERTIFICATE from '@/router/digital-certificate.routes';
import CONTACTUS_ROUTES from '@/router/contact-us.routes';
import PATIENTS_ROUTES from '@/router/patients.routes';
import SPECIALTY_ROUTES from '@/router/specialty.routes';
import SETTINGS_ROUTES from '@/router/settings.routes';
import TERM_ROUTES from '@/router/term.routes';
import DASHBOARD_ROUTES from '@/router/dashboard.routes';

import DashboardLayout from '@/layouts/Dashboard.vue';
import Login from '@/views/Login.vue';
import Home from '@/views/Home.vue';
import Register from '@/views/Register.vue';
import ContactUs from '@/views/ContactUs.vue';
import Financial from '@/views/Financial.vue';
import Schedule from '@/views/Schedule.vue';
import Timetable from '@/views/Timetable.vue';
import MedicalRecordModel from '@/views/MedicalRecordModel.vue';
import DoctorProfile from '@/views/DoctorProfile.vue';
import SecretaryProfile from '@/views/SecretaryProfile.vue';
// import Appointment from '@/views/Appointment.vue';

Vue.use(VueRouter);

const redirectMobileApp = (to, from, next) => {
  if (isMobile) {
    window.location.href = process.env.VUE_APP_ADVEASY_STORE_APP;
    return;
  }

  next();
};

const ifAuthenticated = (to, from, next) => {
  if (store.getters.isAuthenticated) {
    next();
    return;
  }

  next('/login');
};

const routes = [
  {
    path: '/login',
    name: 'login',
    component: Login,
    beforeEnter: redirectMobileApp,
  },
  {
    path: '/register',
    name: 'register',
    component: Register,
    beforeEnter: redirectMobileApp,
  },
  {
    path: '/',
    component: DashboardLayout,
    beforeEnter: ifAuthenticated,
    children: [
      {
        path: '/home',
        name: 'index',
        component: Home,
      },
      {
        path: '/schedule',
        name: 'schedule',
        component: Schedule,
      },
      {
        path: '/timetable',
        name: 'timetable',
        component: Timetable,
      },
      {
        path: '/medical-records',
        name: 'medical-records',
        component: MedicalRecordModel,
      },
      {
        path: '/doctor-profile',
        name: 'doctor-profile',
        component: DoctorProfile,
      },
      {
        path: '/secretary-profile',
        name: 'secretary-profile',
        component: SecretaryProfile,
      },
      {
        path: '/contact-us',
        name: 'contact-us',
        component: ContactUs,
      },
      {
        path: '/financial',
        name: 'financial',
        component: Financial,
      },
      SCHEDULES_ROUTES,
      PRESCRIPTION_MODELS_ROUTES,
      PATIENTS_ROUTES,
      SECRETARIES_ROUTES,
      PROCEDURES_ROUTES,
      DISCOUNTS_ROUTES,
      DIGITAL_CERTIFICATE,
      CONTACTUS_ROUTES,
      SPECIALTY_ROUTES,
      SETTINGS_ROUTES,
      TERM_ROUTES,
      DASHBOARD_ROUTES,
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
