<template>
  <div class="schedule-view">
    <app-header icon="calendar" title="Fale conosco"></app-header>

    <section>
      <div class="card">
        <div class="card-content">
          <b-loading :active.sync="isLoading" :is-full-page="false"></b-loading>
          <div class="row">
            <div class="col-12">
              <b-field
                :label="`Olá ${
                  userName.split(' ')[0]
                }, como pudemos ajudá-lo(a) ?`"
              >
                <b-textarea
                  v-model="message"
                  size="lg"
                  class="textarea-custom input-custom"
                  @keydown="onSendMessage"
                ></b-textarea>
              </b-field>
            </div>
          </div>
          <div class="row mt-5">
            <div class="col-12">
              <b-button
                @click="sendMessage"
                class="button-rounded btn-success border-0 remove-focus px-4"
                :loading="isLoading"
                :disabled="isLoading || !message"
              >
                Enviar
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import ContactUsService from '@/services/contact-us.service';

import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters(['userName']),
  },
  data: () => ({
    isLoading: false,
    message: null,
  }),
  methods: {
    onSendMessage(event) {
      event.ctrlKey && event.keyCode === 13 && this.sendMessage();
    },

    sendMessage() {
      if (this.message.length < 3) return;
      this.isLoading = true;

      ContactUsService.sendMessage({ message: this.message })
        .then(() => {
          this.$buefy.snackbar.open('Mensagem enviada com sucesso!');
        })
        .finally(() => {
          this.isLoading = false;
          this.message = '';
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.textarea-custom {
  height: 120px;
  font-size: 1.1rem;
}
</style>
