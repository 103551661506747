<template>
  <div class="app-file-picker">
    <input
      @change="onFileSelect"
      ref="fileInput"
      type="file"
      style="display: none"
      :accept="accept"
    />
    <label class="label">
      {{ label }}
      <span v-if="isRequired">*</span>
    </label>
    <div class="input-content">
      <div class="value" :class="{ error: errorMessage }">
        <span>{{
          fileName && fileName.length > 20
            ? fileName.slice(0, 25) + '...'
            : fileName
        }}</span>
        <button
          @click="deleteFile"
          v-if="fileName && !disabled"
          type="button"
          class="remove-btn"
        >
          <b-icon size="is-small" icon="times"></b-icon>
        </button>
      </div>
      <button
        @click="onClick"
        type="button"
        :class="disabled ? 'disabled' : ''"
      >
        <b-icon size="is-small" icon="upload"></b-icon>
      </button>
    </div>
    <p v-if="errorMessage" class="input-error">
      {{ errorMessage }}
    </p>
  </div>
</template>

<script>
import FileMixin from '@/mixins/FileMixin';

export default {
  name: 'app-file-picker',
  mixins: [FileMixin],
  model: {
    prop: 'model',
    event: 'input',
  },
  props: [
    'label',
    'model',
    'accept',
    'required',
    'disabled',
    'errors',
    'isMedicalRecord',
  ],
  data: () => {
    return {
      fileName: null,
      fileUrl: null,
    };
  },
  methods: {
    onClick() {
      !this.disabled && this.$refs.fileInput.click();
    },
    async onFileSelect(event) {
      const [file] = event.target.files;
      let url = null;

      const size = Math.round(file.size / 1024);

      if (size > 5120 && this.isMedicalRecord) {
        this.$buefy.snackbar.open({
          message: 'Por favor, selecione um arquivo com menos de 5mb',
          type: 'is-danger',
          duration: 5000,
          pauseOnHover: true,
        });
        return;
      }

      if (!this.accept.includes('image/*') || this.isMedicalRecord) url = file;
      else url = await this.fileToBase64(file);

      this.$refs.fileInput.value = null;
      this.fileName = file.name;

      this.updateModel(url);

      this.$emit('fileSelected', file);
    },
    deleteFile() {
      this.fileName = null;
      this.$emit('fileDeleted');
    },
    updateModel(value) {
      this.$emit('input', value);
    },
  },
  computed: {
    isRequired() {
      return this.required === '' || this.required;
    },
    errorMessage() {
      if (!this.errors || !this.errors.length) return null;
      return this.errors[0];
    },
  },
  mounted() {
    this.fileName = this.model ? this.model.split('/').pop() : this.model;
  },
};
</script>

<style lang="scss" scoped>
.app-file-picker {
  margin: 2px 0;

  label {
    display: block;
    font-size: 16px;
    margin-bottom: 0.5em;

    > span {
      color: var(--color-danger);
      font-size: 0.9em;
      position: relative;
      top: -3px;
      left: -2px;
    }
  }

  .input-content {
    display: flex;
    align-content: center;

    .value {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex: 0 75%;
      border: 1px solid #ddd;
      outline: 0;
      padding: 8px;
      border-radius: 4px 0 0 4px;
      height: 38px;
      font-size: 1em;
      color: #424242;

      &.error {
        border-color: #dc3545;
      }

      .remove-btn {
        margin-top: 5px;
        color: var(--color-danger);
        border: none;
        background: transparent;
        outline: 0;
        font-size: 1.1em;
        cursor: pointer;
        transition: background 0.2s ease-in-out;
      }
    }

    button:not(.remove-btn) {
      flex: 0 25%;
      border: none;
      background: var(--color-secondary);
      color: #fff;
      border-radius: 0 8px 8px 0;
      outline: 0;
      transition: background 0.2s ease-in-out;
      cursor: pointer;

      &:hover {
        background: var(--color-secondary);
      }

      .icon {
        font-size: 1.3em;
        margin-right: 4px;
      }
    }

    button:not(.remove-btn).disabled {
      background: gray;
      cursor: not-allowed;
    }
  }

  .input-error {
    color: #dc3545;
    display: block;
    font-size: 0.75rem;
    margin-top: 0.25rem;
  }
}
</style>
