<template>
  <div class="app-header">
    <div :class="`app-header__content ${danger ? 'danger' : ''}`">
      <b-button
        v-if="!['register', 'login'].includes($router.currentRoute.name)"
        class="bg-transparent remove-focus border-0"
        v-b-toggle.sidebar-dashboard
        @click="toggleSidebar"
      >
        <b-icon icon="list" class="icon-toggle-sidebar"></b-icon>
      </b-button>

      <b-menu-item class="icon" :icon="iconCustom" ref="aref"></b-menu-item>

      <h2>{{ titleCustom }}</h2>

      <b-icon
        v-if="toastDescription"
        class="question-help"
        icon="question-circle-fill"
        v-b-tooltip.hover="toastDescription"
      ></b-icon>
    </div>

    <div class="app-header__buttons">
      <router-link v-if="backRoute" :to="backRoute">
        <b-button type="is-text">
          <b-icon icon="chevron-left"></b-icon>
          Voltar
        </b-button>
      </router-link>

      <b-button
        v-if="!backRoute && goesBack"
        @click="goBack()"
        type="is-text"
        class="button-primary"
      >
        <b-icon icon="chevron-left"></b-icon>
        Voltar
      </b-button>
    </div>
  </div>
</template>

<script>
import MENUS from '@/constants/menu.constant';
import { CHANGE_SIDEBAR } from '@/store/actions/app';

export default {
  props: {
    icon: String,
    title: String,
    description: {
      type: String,
      default: null
    },
    backRoute: Object,
    goesBack: {
      type: Boolean,
      default: false,
    },
    danger: {
      type: Boolean,
      default: false,
    },
  },

  mounted() {
    this.currentRoute =
      MENUS.find((x) => x.routeName === this.$router.currentRoute.name) ?? {};

    const tagA = this.$refs.aref.$el.querySelector('a');
    tagA.style.color = '#182734';
  },

  computed: {
    iconCustom() {
      return this.icon ?? this.currentRoute.icon;
    },

    titleCustom() {
      return this.title ?? this.currentRoute.label;
    },

    toastDescription() {
      return this.currentRoute.description ?? this.description;
    }
  },

  data: () => ({
    currentRoute: {},
  }),

  methods: {
    goBack() {
      this.$router.go(-1);
    },

    toggleSidebar() {
      this.$store.commit(CHANGE_SIDEBAR);
    },
  },
};
</script>

<style lang="scss" scoped>
.icon-toggle-sidebar {
  font-size: 1.5rem;
  color: #182734 !important;
}
.app-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 0;
  margin-bottom: 16px;
  border-bottom: 1px inset #e5e5e5;

  &__content {
    display: flex;
    align-items: center;
    color: var(--text-color-medium);
    gap: 0.4rem;

    .icon {
      color: #182734 !important;
    }

    h2 {
      font: normal 500 1.3rem 'Poppins', sans-serif;
      letter-spacing: 0px;
      color: #182734;
    }
  }

  &__content.danger {
    .icon,
    h2 {
      fill: #dc3545;
    }
  }
}
</style>
