<template>
  <div class="dashboard-layout">
    <section :class="`menu-container ${pinMenuClass}`">
      <b-sidebar
        id="sidebar-dashboard"
        position="static"
        type="is-white"
        no-close-on-route-change
        no-header
        no-close-on-esc
        shadow
        visible
        open
        body-class="sidebar-body-custom"
      >
        <div :class="`row p-0 py-2 m-0 ${pinMenu ? 'fixed-menu' : 'logo-ctn'}`">
          <div class="col-10 mx-auto me-2">
            <b-img
              fluid
              :src="require('@/assets/img/logo_oficial.png')"
              alt="logo Adv Easy"
            />
          </div>

          <div class="col-12 logged-name">
            <span>{{ userLoggedName }}</span>
          </div>

          <div class="col-12 mt-3 mb-0">
            <b-icon
              class="question-toggle-help"
              icon="question-circle-fill"
              v-b-tooltip.hover="
                'Defina-se como Online para ficar disponível em tempo real para clientes e, quando não puder, é só virar a chave para Offline. Nas duas opções, você ainda poderá ser acionado para atendimento agendado'
              "
            ></b-icon>

            <toggle-button
              :value="online"
              @change="onChangeOnline"
              :disabled="isRouterDisabled('toggle-online')"
              sync
              :width="63"
              :labels="{
                checked: 'Online',
                unchecked: 'Offline',
              }"
            />
          </div>
        </div>

        <b-menu :activable="false" class="px-2">
          <b-menu-list class="menu-list m-0" label="Menu" type="is-primary">
            <router-link
              tag="div"
              class="col-11 p-0 position-relative"
              v-for="menu in menus"
              :key="menu.routeName"
              :to="{ name: menu.routeName }"
              :exact="menu.routeName === 'index'"
              :class="{
                disabled: isRouterDisabled(menu.routeName),
              }"
              :ref="`${menu.routeName}-menu`"
              @click.native="animateMenu(`${menu.routeName}-menu`)"
            >
              <b-menu-item :icon="menu.icon" :label="menu.label"> </b-menu-item>
            </router-link>
          </b-menu-list>

          <b-menu-list label="Ações">
            <router-link
              tag="div"
              :to="{ name: 'contact-us' }"
              :exact="'contact-us' === 'index'"
            >
              <b-menu-item
                icon="user-friends"
                label="Fale conosco"
              ></b-menu-item>
            </router-link>

            <b-menu-item
              @click="onLogoutClick()"
              icon="sign-out-alt"
              label="Sair"
            ></b-menu-item>
          </b-menu-list>
        </b-menu>

        <div id="images">
          <a href="https://www.facebook.com/adveasy/" target="_blank">
            <img
              alt="Facebook"
              src="@/assets/img/facebook-square-brands.svg"
              width="20"
              class="outer"
            />
          </a>
          &nbsp;
          <a href="https://www.instagram.com/adveasyapp" target="_blank">
            <img
              alt="Instagram"
              src="@/assets/img/instagram-brands.svg"
              width="20"
              class="outer"
            />
          </a>
        </div>
      </b-sidebar>
    </section>

    <section class="router-container custom-scroll" ref="router">
      <router-view @calendar_loaded="calendarLoaded"></router-view>
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import DoctorService from '@/services/doctor.service';
import MENUS from '@/constants/menu.constant';
import { AUTH_LOGOUT } from '@/store/actions/auth';
import {
  CHANGE_ONLINE,
  CHANGE_PAGARME_ACCOUNT_STATUS,
} from '@/store/actions/app';
import DoctorAccountStage from '@/enums/DoctorAccountStage';
// import JunoDocumentsStatus from '@/constants/document_juno_status.constant';
import PagarmeAccountStatus from '@/constants/pagarme_account_status.constant';

export default {
  data: () => ({
    menus: MENUS,
    pinMenu: true,
    is_account_stage_incomplete: false,
  }),

  mounted() {
    this.is_account_stage_incomplete =
      this.accountStage !== DoctorAccountStage.COMPLETE;

    this.getDoctorOnline();

    const is_suspicious_account = [
      PagarmeAccountStatus.BLOCKED,
      PagarmeAccountStatus.INACTIVE,
    ].includes(this.pagarmeAccountStatus);

    if (is_suspicious_account) {
      this.$buefy.snackbar.open({
        message:
          'Sua conta está bloqueada ou inativa, entre em contato com o suporte.',
        type: 'is-warning',
        duration: 35000,
        position: 'is-top',
        pauseOnHover: true,
      });
    }
  },

  beforeUpdate() {
    if (this.is_account_stage_incomplete)
      this.is_account_stage_incomplete =
        this.accountStage !== DoctorAccountStage.COMPLETE;
  },

  computed: {
    ...mapGetters([
      'accountStage',
      'readOnly',
      'userName',
      'secretaryId',
      'doctorId',
      'secretaryName',
      'online',
      'junoDocumentsStatus',
      'showSidebar',
      'pagarmeAccountStatus',
    ]),

    pinMenuClass() {
      return this.showSidebar ? 'fixed' : 'no-fixed';
    },

    userLoggedName() {
      return this.secretaryId
        ? `Olá, ${this.secretaryName.split(' ')[0]}`
        : `Olá, Dr(a) ${this.userName.split(' ')[0]}`;
    },
  },

  methods: {
    getDoctorOnline() {
      DoctorService.getId(this.doctorId)
        .then(({ data: doctor }) => {
          /* this.$store.commit(
            CHANGE_DOCUMENTS_STATUS,
            doctor.juno_documents_status
          ); */
          this.$store.commit(
            CHANGE_PAGARME_ACCOUNT_STATUS,
            doctor.pagarme_account_status
          );

          this.$store.commit(CHANGE_ONLINE, !!doctor.on_line);
        })
        .catch(() => console.error('ERROR ao definir online'));
    },

    onChangeOnline(event) {
      this.$store.dispatch(CHANGE_ONLINE, event.value);
    },

    onLogoutClick() {
      this.$buefy.dialog.confirm({
        message: 'Deseja realmente sair?',
        onConfirm: () => this.logout(),
      });
    },

    logout() {
      this.$store.dispatch(AUTH_LOGOUT).then(() => this.$router.push('/login'));
    },

    isRouterDisabled(routeName) {
      const routes_not_disabled = ['schedule', 'contact-us'];

      const enumPagarmeAccountStatus = [
        PagarmeAccountStatus.REFUSED,
        PagarmeAccountStatus.SUSPENDED,
        PagarmeAccountStatus.BLOCKED,
        PagarmeAccountStatus.INACTIVE,
      ];

      const is_account_awaiting =
        this.pagarmeAccountStatus === PagarmeAccountStatus.REGISTRATION;
      const is_account_refused =
        this.pagarmeAccountStatus === PagarmeAccountStatus.REFUSED;
      const is_account_invalid = enumPagarmeAccountStatus.includes(
        this.pagarmeAccountStatus
      );

      if (
        this.is_account_stage_incomplete ||
        is_account_awaiting ||
        is_account_refused ||
        !this.pagarmeAccountStatus
      ) {
        routes_not_disabled.push('doctor-profile');
      }

      return (
        (this.is_account_stage_incomplete || is_account_invalid) &&
        !routes_not_disabled.includes(routeName)
      );
    },

    calendarLoaded() {
      this.$nextTick(() => {
        this.$refs.router.scrollTop = 235;
      });
    },

    animateMenu(menu) {
      if (!(menu in this.$refs)) return;

      const menuRef = this.$refs[menu][0];

      menuRef.$el.classList.add('waves-effect');

      setTimeout(() => {
        menuRef.$el.classList.remove('waves-effect');
      }, 900);
    },
  },
};
</script>

<style lang="scss">
@keyframes waves-effect {
  0% {
    width: 0;
    opacity: 0;
  }
  50% {
    width: 50%;
    opacity: 1;
  }

  70% {
    width: 100%;
    opacity: 1;
  }

  100% {
    width: 100%;
    opacity: 0;
  }
}

.waves-effect {
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    background-color: #b58e1c35;
    opacity: 0.5;
    z-index: -1;

    animation: waves-effect 0.35s cubic-bezier(0.7, 0.76, 0.13, 0.26) normal;
  }
}

.disabled {
  pointer-events: none;
  opacity: 0.7;
}

.outer::after {
  width: 100%;
  height: 0;
  display: inline-block;
  content: '';
}

#images {
  text-align: center;
  margin: 5px;
}

div.dashboard-layout {
  display: flex;
  height: 100vh;

  section.menu-container {
    height: 100vh;
    width: 0;
    overflow-y: scroll;
    transition: width 0.3s ease-in-out;
    overflow: hidden;
    background: transparent;

    & .menu-list a {
      color: var(--color-secondary);
      text-decoration: none;
    }

    & .router-link-active {
      & a {
        color: var(--color-primary);
        background: #b58e1c30;
        font-weight: 600;
      }
    }

    &:hover {
      overflow-y: auto;

      &::-webkit-scrollbar-thumb {
        background: var(--color-primary);
      }

      &::-webkit-scrollbar {
        width: 8px;

        background-color: #f7f5f5;
      }
    }

    &.fixed,
    & .b-sidebar {
      width: 260px;
      direction: rtl;

      .menu-label {
        display: block;
        direction: ltr;
      }

      .menu-list {
        direction: ltr;
      }
    }

    & .menu-list {
      margin-left: 0;
    }

    & .menu-list .icon {
      vertical-align: middle;
      margin-right: 10px;
    }
  }

  section.router-container {
    flex: 1;
    height: 100vh;
    max-height: 100vh;
    overflow-y: scroll;
    background: #f9f9f9;
    padding: 1.4rem;
  }
}

.question-toggle-help {
  font-size: 13px;
  margin-left: 0.2rem;
  cursor: help;
}
</style>