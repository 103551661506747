<template>
  <div class="timetable-view">
    <app-header icon="clock" title="Meus Horários"></app-header>

    <section>
      <div class="card">
        <header class="card-header">
          <div class="card-header-title"></div>

          <div class="card-header-buttons">
            <b-button
              id="btnAddAppointment"
              class="button-rounded button-primary"
              @click="newTime()"
              size="is-small"
              icon-left="plus"
            >
              Adicionar
            </b-button>
            <b-tooltip
              title="Adicionar novo Horário"
              target="btnAddAppointment"
            >
            </b-tooltip>
          </div>
        </header>

        <div class="card-content">
          <b-loading :active.sync="isLoading" :is-full-page="false"></b-loading>

          <b-tabs
            class="time-schedule"
            active-nav-item-class="border-top-0 border-left-0 border-right-0 border-success"
            content-class="mt-3"
          >
            <b-tab
              v-for="(weekday, i) in weekdays"
              :key="`item-tab-${weekday}`"
              :title="weekday"
              class="time-schedule-item"
              title-link-class="time-schedule-menu-link"
              lazy
            >
              <b-card
                v-for="(time, idx) in days[i]"
                :key="`t${idx}`"
                :class="`time-schedule-card ${getTimeClass(
                  time
                )} col-12 mx-auto col-lg-9 mx-lg-0`"
              >
                <b-button
                  @click="deleteTime(time)"
                  type="is-text"
                  size="is-small"
                  class="button-delete-time"
                >
                  <b-icon icon="trash"></b-icon>
                </b-button>
                <p class="text-time">
                  {{ time.start | time }} - {{ time.end | time }}
                </p>

                <p class="text-date">
                  {{ formatDate(time.date_start, time.date_end) }}
                </p>

                <p class="mb-0">
                  <b-badge
                    v-for="appointment in time.appointment_types"
                    :key="`appointment-${appointment.id}`"
                    variant="success"
                    class="badge-appointment"
                  >
                    {{ appointment.name }}
                  </b-badge>
                </p>
              </b-card>

              <b-button
                v-if="hasHours"
                class="delete-all-button button-rounded"
                size="is-small"
                icon-left="plus"
                @click="onClearClick(i)"
              >
                Remover horários
              </b-button>
            </b-tab>
          </b-tabs>

          <no-content
            v-if="isEmpty"
            text="Nenhum horário cadastrado."
            icon="clock"
          ></no-content>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import * as moment from 'moment';

import SchedulesService from '@/services/schedules.service';
import TimeSchedule from '@/modals/TimeSchedule.vue';

export default {
  mounted() {
    this.loadTimes();
  },

  computed: {
    isEmpty() {
      return Object.keys(this.days).reduce((a, b) => a + b.length, 0) === 0;
    },
    hasHours() {
      for (let i = 0; i <= 6; i++) {
        if (this.days[i]) {
          return true;
        }
      }

      return false;
    },
  },

  filters: {
    time(value) {
      const [hour, min] = value.split(':');
      return `${hour}:${min}`;
    },
  },

  data: () => ({
    isLoading: false,
    days: {},
    weekdays: moment.weekdays(),
    standard_times: [30, 45, 60, 75, 90, 105, 120],
  }),

  methods: {
    loadTimes() {
      this.isLoading = true;

      SchedulesService.getAvailabilities()
        .then((times) => {
          this.days = times;
        })
        .finally(() => setTimeout(() => (this.isLoading = false), 300));
    },

    newTime() {
      this.$buefy.modal.open({
        parent: this,
        component: TimeSchedule,
        hasModalCard: true,
        trapFocus: true,
        events: {
          close: (data) => data && this.loadTimes(),
        },
      });
    },

    deleteTime({ weekday, id }) {
      SchedulesService.deleteAvailability(id).then(() => {
        this.days[weekday] = this.days[weekday].filter(
          (time) => time.id !== id
        );
        this.$buefy.snackbar.open('Horário excluído com sucesso!');
      });
    },

    onClearClick() {
      this.$buefy.dialog.confirm({
        message: `Deseja realmente excluir todos os horários de atendimento?`,
        onConfirm: () => this.clearWeekday(),
      });
    },

    clearWeekday() {
      this.isLoading = true;
      const promises = [];

      for (let i = 0; i <= 6; i++) {
        this.days[i] &&
          this.days[i].map((scd) =>
            promises.push(
              SchedulesService.deleteAvailability(scd.id).then(
                () => (this.days[i] = [])
              )
            )
          );
      }

      Promise.all(promises)
        .then(() => {
          this.$buefy.snackbar.open('Horários excluídos com sucesso!');
        })
        .finally(() => (this.isLoading = false));
    },

    formatDate(date_start, date_end) {
      let date_result = '';
      date_result = date_start ? moment(date_start).format('DD/MM/YYYY') : '';
      date_result += date_start && date_end ? ' - ' : '';
      date_result += date_end ? moment(date_end).format('DD/MM/YYYY') : '';
      return date_result;
    },

    getTimeClass({ appointment_types }) {
      return appointment_types.map(({ id }) => `apt-${id}`);
    },
  },
};
</script>

<style lang="scss" scoped>
.caption {
  margin-bottom: 8px !important;

  &__item {
    display: flex;
    align-items: center;

    .color {
      height: 12px;
      width: 12px;
      border-radius: 50%;
      margin-right: 8px;
    }

    p {
      font-size: 1rem;
    }
  }
}

.time-schedule {
  display: block;

  /* & .time-schedule-item {
  } */

  & .time-schedule-card {
    border-radius: 0.6rem;
    margin-bottom: 0.5em;
    border: 1px solid #00000020;
    box-shadow: 0 0 4px 1px #33333324;

    & .card-body {
      padding: 0.6em;
    }
  }

  & .text-time {
    font-size: 0.9rem;
    font-weight: 600;
    color: #333333;
    margin-bottom: 0.2em;
  }

  & .text-date {
    font-size: 0.8rem;
    font-weight: 500;
    color: #333333;
    margin-bottom: 0;
  }

  & .badge-appointment {
    padding: 0.3em 0.5em;
    margin-right: 5px;

    &:last-child {
      margin-right: 0;
    }
  }

  & .button-delete-time {
    &,
    &:hover,
    &:active,
    &:focus,
    &:focus-visible {
      background: transparent !important;
      float: right !important;
      border: 0 !important;
      box-shadow: none !important;
      margin-top: calc(2.2rem / 2) !important;
      color: #ff6247 !important;
    }
  }

  & .delete-all-button {
    position: absolute;
    right: 1%;
    top: calc(13rem / 2) !important;

    background-color: #ff6247;
    border: 0 !important;
  }
}
</style>
