export default {
    PAID: 'paid',
    PENDING: 'pending',
    PROCESSING: 'processing',
    FAILED: 'failed',
    AUTHORIZED_PENDING_CAPTURE: 'authorized_pending_capture',
    NOT_AUTHORIZED: 'not_authorized',
    CAPTURED: 'captured',
    PARTIAL_CAPTURE: 'partial_capture',
    WAITING_CAPTURE: 'waiting_capture',
    REFUNDED: 'refunded',
    PARTIALLY_REFUNDED: 'partial_refunded',
    REFUND_ERROR: 'error_on_refunding',
    CANCELED: 'voided',
    WAITING_CANCELLATION: 'waiting_cancellation',
    CANCELLATION_ERROR: 'error_on_voiding',
    WITH_ERROR: 'with_error'
};
