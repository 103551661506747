export default [
  /* {
    icon: 'home',
    label: 'Dashboard',
    routeName: 'dashboard',
    description: null,
  }, */
  {
    icon: 'calendar',
    label: 'Agenda',
    routeName: 'schedule',
    description:
      'Visualização das consultorias agendadas, selecione diário ou mensal e deslize sobre os períodos',
  },
  {
    icon: 'clock',
    label: 'Meus Horários',
    routeName: 'timetable',
    description:
      'Cadastre sua agenda para os clientes marcarem consultorias no melhor momento para ambos, em qualquer dia da semana, você poderá configurar vários horários de atendimento',
  },
  {
    icon: 'clipboard',
    label: 'Atendimentos',
    routeName: 'schedules.index',
    description:
      'O histórico das suas consultorias ficam aqui, utilize os filtros para encontrar o que precisa',
  },
  // {
  //   icon: 'users',
  //   label: 'Clientes',
  //   routeName: 'patients.index',
  //   description:
  //     'A pessoa que lhe contatou pelo AdvEasy vira seu cliente, mas você também poderá cadastrar novos clientes por aqui, e o mais legal, é que você também poderá marcar atendimentos, além de enviar links para pagamentos antecipados, confira',
  // },
  {
    icon: 'user',
    label: 'Perfil',
    routeName: 'doctor-profile',
    description: null,
  },
  // {
  //   icon: 'wallet',
  //   label: 'Financeiro',
  //   routeName: 'financial',
  //   description:
  //     'Seus recebíveis estão aqui, lembre-se que o valor de uma consultoria fica disponível pra você em 31 dias, por isso o campo "Saldo a liberar", e quando estiver liberado, o campo "Saldo transferível" irá mostrar estes valores, a partir daí, é só enviar para o seu banco pessoal cadastrado',
  // },
];
