import Api from './api.service';
import groupBy from 'lodash/groupBy';

export default {
  get: (params = {}) => Api.get('schedules', { params }),

  getId: (id, params) => Api.get(`schedules/${id}`, { params }),

  store: (data) => Api.post('schedules', data),

  getAvailabilities: (params = {}) =>
    Api.get('schedules-availabilities', { params }).then(({ data }) =>
      groupBy(data, 'weekday')
    ),

  storeAvailability: (data) => Api.post('schedules-availabilities', data),

  deleteAvailability: (id) => Api.delete(`schedules-availabilities/${id}`),

  cancelSchedule: (id, data) =>
    Api.patch(`schedules/${id}/cancel`, {
      ...data,
    }),

  export: (params) => Api.get('schedules/export', { params }),

  getCheckouUrl: (scheduleId) =>
    Api.get(`schedules/${scheduleId}/get-checkout-link`),

  getScheduleList: (doctorId) => Api.get(`lawyers/${doctorId}/schedule-list`),

  getScheduleMeetRecords: (scheduleId) =>
    Api.get(`schedules/${scheduleId}/meet-records`),

  confirmeScheduleOnline: (scheduleId) =>
    Api.get(`/schedules/confirme-schedule-online/${scheduleId}`),

  cancelScheduleOnline: (scheduleId) =>
    Api.get(`/schedules/cancel-schedule-online/${scheduleId}`),
};
