<template>
  <div class="balance-transfer-modal">
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Qual valor deseja transferir?</p>
      </header>
      <section class="modal-card-body">
        <div class="row mt-2">
          <div class="col-12">
            <div class="alert">
              A transferência será feita para a conta e CPF informados no
              cadastro.
            </div>
          </div>
          <div class="col-12">
            Você pode realizar uma transferência de até
            <b>{{ transferableValue | currency('BRL') }}</b>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-12">
            <b-field :message="hasError" :type="inputType">
              <b-input
                v-model="value"
                icon="dollar-sign"
                placeholder="Informe um valor"
                @keyup="onFormatMoney"
              >
              </b-input>
            </b-field>
          </div>
        </div>
      </section>

      <footer class="modal-card-foot is-right">
        <b-button
          class="bg-secondary button-rounded border-secondary mr-3 px-3"
          @click="$emit('close', false)"
        >
          Cancelar
        </b-button>

        <b-button
          class="bg-success button-rounded border-success px-3"
          style="cursor: pointer"
          @click="transferBalance"
          :disabled="!!hasError || !value"
          >
            Transferir
          </b-button
        >
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    transferableValue: Number,
  },
  computed: {
    hasError() {
      if(!this.original_value) return '';

      return this.original_value > this.transferableValue || this.original_value <= 0
        ? ['Saldo inválido para transferência']
        : null;
    },

    inputType() {
      if(!this.original_value) return '';

      return this.original_value > this.transferableValue ? 'is-danger' : '';
    },

  },
  data: () => ({
    value: null,
    original_value: null,
    errors: [],
  }),

  methods: {
    transferBalance() {
      this.$emit('close', { value: this.original_value });
    },

    formatValue(value) {
      return value.replace(/[^\d,]/g, '').replace(',', '.');
    },

    onFormatMoney(event) {
      if (!event.target?.value?.trim()) return;

        let value = event.target.value.replace(/\D+/g, '');

        if(value.length == 4) {
          value = value.replace(/(\d{1})(\d{3})/g, '$1.$2');
        }

        value = value.replace(/(^[0-9]{1,2})*?(([0-9]{3})+)([0-9]{2}$)/g, (_, ...pN) => {
          debugger
            pN.pop();
            pN.pop();

            pN = pN.filter((x) => x);
            const lastPN = pN.pop();
            pN.pop();
            const firstPN = pN.length >= 2 && pN.length - (1 % 3) != 0 ? `${pN.shift()}.` : "";

            pN = pN[0]?.replace(/([0-9]{3}(?=[0-9]{3,4}))/g, "$1.") || "";

            return `${firstPN}${pN},${lastPN}`;
        });

        event.target.value = `R$ ${value}`;
        this.original_value = this.formatValue(value);
    }
  },
};
</script>

<style lang="scss" scoped>
.alert {
  margin-bottom: 10px;
  color: gray;
}
</style>
