import Api from './api.service';

const platform = 'doctor';

export default {
  login: (data) => Api.post('auth/login', { ...data, platform }),
  logout: () => Api.get(`auth/logout?platform=${platform}`),
  refresh: () => Api.get(`auth/refresh?platform=${platform}`, null),
  store: (data) => Api.post('lawyers', data),
};
