var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dashboard-layout"},[_c('section',{class:("menu-container " + _vm.pinMenuClass)},[_c('b-sidebar',{attrs:{"id":"sidebar-dashboard","position":"static","type":"is-white","no-close-on-route-change":"","no-header":"","no-close-on-esc":"","shadow":"","visible":"","open":"","body-class":"sidebar-body-custom"}},[_c('div',{class:("row p-0 py-2 m-0 " + (_vm.pinMenu ? 'fixed-menu' : 'logo-ctn'))},[_c('div',{staticClass:"col-10 mx-auto me-2"},[_c('b-img',{attrs:{"fluid":"","src":require('@/assets/img/logo_oficial.png'),"alt":"logo Adv Easy"}})],1),_c('div',{staticClass:"col-12 logged-name"},[_c('span',[_vm._v(_vm._s(_vm.userLoggedName))])]),_c('div',{staticClass:"col-12 mt-3 mb-0"},[_c('b-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:(
              'Defina-se como Online para ficar disponível em tempo real para clientes e, quando não puder, é só virar a chave para Offline. Nas duas opções, você ainda poderá ser acionado para atendimento agendado'
            ),expression:"\n              'Defina-se como Online para ficar disponível em tempo real para clientes e, quando não puder, é só virar a chave para Offline. Nas duas opções, você ainda poderá ser acionado para atendimento agendado'\n            ",modifiers:{"hover":true}}],staticClass:"question-toggle-help",attrs:{"icon":"question-circle-fill"}}),_c('toggle-button',{attrs:{"value":_vm.online,"disabled":_vm.isRouterDisabled('toggle-online'),"sync":"","width":63,"labels":{
              checked: 'Online',
              unchecked: 'Offline',
            }},on:{"change":_vm.onChangeOnline}})],1)]),_c('b-menu',{staticClass:"px-2",attrs:{"activable":false}},[_c('b-menu-list',{staticClass:"menu-list m-0",attrs:{"label":"Menu","type":"is-primary"}},_vm._l((_vm.menus),function(menu){return _c('router-link',{key:menu.routeName,ref:((menu.routeName) + "-menu"),refInFor:true,staticClass:"col-11 p-0 position-relative",class:{
              disabled: _vm.isRouterDisabled(menu.routeName),
            },attrs:{"tag":"div","to":{ name: menu.routeName },"exact":menu.routeName === 'index'},nativeOn:{"click":function($event){return _vm.animateMenu(((menu.routeName) + "-menu"))}}},[_c('b-menu-item',{attrs:{"icon":menu.icon,"label":menu.label}})],1)}),1),_c('b-menu-list',{attrs:{"label":"Ações"}},[_c('router-link',{attrs:{"tag":"div","to":{ name: 'contact-us' },"exact":'contact-us' === 'index'}},[_c('b-menu-item',{attrs:{"icon":"user-friends","label":"Fale conosco"}})],1),_c('b-menu-item',{attrs:{"icon":"sign-out-alt","label":"Sair"},on:{"click":function($event){return _vm.onLogoutClick()}}})],1)],1),_c('div',{attrs:{"id":"images"}},[_c('a',{attrs:{"href":"https://www.facebook.com/adveasy/","target":"_blank"}},[_c('img',{staticClass:"outer",attrs:{"alt":"Facebook","src":require("@/assets/img/facebook-square-brands.svg"),"width":"20"}})]),_vm._v("   "),_c('a',{attrs:{"href":"https://www.instagram.com/adveasyapp","target":"_blank"}},[_c('img',{staticClass:"outer",attrs:{"alt":"Instagram","src":require("@/assets/img/instagram-brands.svg"),"width":"20"}})])])],1)],1),_c('section',{ref:"router",staticClass:"router-container custom-scroll"},[_c('router-view',{on:{"calendar_loaded":_vm.calendarLoaded}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }