import * as AppActions from '@/store/actions/app';

import DoctorService from '@/services/doctor.service';

const state = {
  showSidebar: true,
  on_line: false,
  documentStatus: false,
  pagarme_account_status: false,
};

const getters = {
  online: (state) => state.on_line,
  junoDocumentsStatus: (state) => state.documentStatus,
  showSidebar: (state) => state.showSidebar,
  pagarmeAccountStatus: (state) => state.pagarme_account_status,
};

const actions = {
  [AppActions.CHANGE_ONLINE]: ({ commit, getters }, on_line) => {
    return new Promise((resolve, reject) => {
      DoctorService.updateOnline(on_line, getters.doctorId)
        .then(() => {
          commit(AppActions.CHANGE_ONLINE, on_line);
          resolve(on_line);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

const mutations = {
  [AppActions.CHANGE_SIDEBAR]: (state) => {
    state.showSidebar = !state.showSidebar;
  },

  [AppActions.CHANGE_ONLINE]: (state, on_line) => {
    state.on_line = on_line;
  },

  [AppActions.CHANGE_DOCUMENTS_STATUS]: (state, status) => {
    state.documentStatus = status;
  },

  [AppActions.CHANGE_PAGARME_ACCOUNT_STATUS]: (state, status) => {
    state.pagarme_account_status = status;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
