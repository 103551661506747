<template>
  <b-field
    :label="label"
    :type="inputType"
    :message="errorMessage"
    :class="{ required: isRequired }"
  >
    <b-autocomplete
      class="input-custom"
      ref="autoComplete"
      @typing="getData"
      @select="onSelect"
      :data="data"
      :placeholder="placeholder"
      :field="field"
      :loading="isFetching"
      :keep-first="keepFirst !== undefined ? keepFirst : true"
      :disabled="disabled"
    ></b-autocomplete>
  </b-field>
</template>

<script>
import debounce from 'lodash/debounce';

export default {
  props: {
    label: String,
    getter: Function,
    setter: Function,
    placeholder: String,
    field: String,
    keepFirst: Boolean,
    isRequired: Boolean,
    errors: {
      type: Array,
      default: () => [],
    },
    value: {
      type: null,
    },
    flag: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  model: {
    prop: 'model',
    event: 'select',
  },
  data: () => ({
    innerValue: null,
    selected: null,
    data: [],
    staticData: [],
    isFetching: false,
  }),
  computed: {
    inputType() {
      if (this.errors && this.errors.length) return 'is-danger';
      return null;
    },
    errorMessage() {
      if (!this.errors || !this.errors.length) return null;
      return this.errors[0];
    },
  },
  methods: {
    onSelect(option) {
      this.flag === 'bank' &&
        this.$emit('select', option ? option.bank_number : null);
      this.flag !== 'bank' && this.$emit('select', option ? option.id : null);
    },

    getData(name) {
      this.flag === 'crm' && this.getStaticData(name);
      this.flag !== 'crm' && this.getAsyncData(name);
    },

    getStaticData(name) {
      if (!name) {
        this.data = this.staticData;
      }

      this.data = this.staticData.filter((opt) => {
        return opt.name.toLowerCase().indexOf(name.toLowerCase()) >= 0;
      });
    },

    getAsyncData: debounce(function (name) {
      if (!name.length) {
        this.data = [];
        return;
      }

      this.isFetching = true;
      this.getter(name)
        .then(({ data }) => (this.data = [...data]))
        .catch(() => (this.data = []))
        .finally(() => {
          this.isFetching = false;
        });
    }, 500),

    checkInArray(value) {
      const idx = this.data.findIndex((el) => el.id == value);
      if (idx === -1) this.loadResourceById(value);
    },
    
    loadResourceById(id) {
      this.loading = true;

      this.setter(id)
        .then(({ data: resource }) => {
          this.data = [resource];
          this.$refs.autoComplete.setSelected(resource);
        })
        .finally(() => (this.loading = false));
    },
  },
  mounted() {
    if (this.flag === 'crm') {
      this.getter('')
        .then(({ data }) => (this.staticData = [...data]))
        .catch(() => (this.staticData = []));
    }

    this.innerValue = this.value;
    if (this.innerValue) this.checkInArray(this.innerValue);
  },
};
</script>

<style lang="scss" scoped>
.input-custom {
  & input {
    &:focus,
    &:focus-visible {
      border-color: #b58e1c90 !important;
      outline: 0 !important;
      box-shadow: 0 0 0 0.2rem #b58e1c25 !important;
    }
  }
}
</style>
