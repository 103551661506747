<template>
  <div class="schedule-status">
    <div class="schedule-status__content">
      <b-badge v-if="schedule.specialty" class="badge-status specialty" pill>
        Especialidade: {{ schedule.specialty.name }}
      </b-badge>

      <b-badge class="badge-status appointment_type" pill>
        Tipo: {{ scheduleType }}
      </b-badge>

      <b-badge
        class="badge-status"
        :variant="`${schedule.schedule_status.type}`"
        pill
      >
        Status: {{ schedule.schedule_status.label }}
      </b-badge>

      <b-badge
        v-if="schedule.value > 0"
        class="badge-status"
        variant="success"
        pill
      >
        Valor: {{ schedule.value | currency }}
      </b-badge>

      <b-badge
        class="badge-status"
        :variant="`${schedule.payment_status.type}`"
        pill
      >
        Status Pgto.: {{ schedule.payment_status.label }}
      </b-badge>

      <b-badge class="badge-status" variant="secondary" pill>
        Criada em:
        {{ schedule.created_at | date('DD/MM/YYY [ás] HH:mm') }}
      </b-badge>

      <b-badge class="badge-status" variant="secondary" pill>
        Criada internamente: {{ internalSchedule }}
      </b-badge>

      <b-badge class="badge-status" variant="secondary" pill>
        Marcada para:
        {{ schedule.start | date('DD/MM/YYYY [às] HH:mm') }}
      </b-badge>

      <b-badge class="badge-status" variant="secondary" pill>
        Tempo de consulta: {{ labelTime }}
      </b-badge>

      <b-badge
        class="badge-status"
        v-if="schedule.rescheduled_to"
        variant="primary"
        pill
      >
        Remarcada {{ +schedule.value ? 'pelo cliente' : '' }} para:
        {{ schedule.rescheduled_to | date('DD/MM/YYYY [às] HH:mm') }}
      </b-badge>

      <b-badge
        class="badge-status"
        v-if="schedule.reschedule_from"
        rounded
        variant="primary"
        pill
      >
        Remarcação de consulta de:
        {{ schedule.reschedule_from.start | date('DD/MM/YYYY [às] HH:mm') }}
      </b-badge>

      <b-badge
        v-if="schedule.canceled_at && !schedule.rescheduled_to"
        class="badge-status"
        variant="danger"
        pill
      >
        Cancelada pelo {{ canceledBy }} em:
        {{ schedule.canceled_at | date('DD/MM/YYYY [às] HH:mm') }}
      </b-badge>

      <b-badge
        v-if="schedule.discount_generated"
        class="badge-status"
        variant="success"
        pill
      >
        Voucher gerado: {{ schedule.discount_generated.code }}
      </b-badge>

      <b-badge
        v-if="schedule.discount_used"
        class="badge-status"
        variant="success"
        pill
      >
        Voucher usado: {{ schedule.discount_used.code }}
      </b-badge>

      <b-badge
        v-if="
          schedule.canceled_at &&
          schedule.discount_generated &&
          !schedule.canceled_by &&
          isChargeRefunded(schedule.pagarme_charge)
        "
        class="badge-status"
        variant="secondary"
        pill
      >
        Voucher recusado pelo cliente
      </b-badge>
    </div>
  </div>
</template>

<script>
import AppointmentTypeEnum from '@/enums/AppointmentTypeEnum';
import ChargeStatusEnum from '@/enums/ChargeStatusEnum';

export default {
  props: {
    schedule: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    scheduleType() {
      const type = this.schedule.appointment_type_id;

      if (type == AppointmentTypeEnum.CHAT) return 'Chat';
      else if (type == AppointmentTypeEnum.CALL) return 'Ligação';

      return 'Vídeo Chamada';
    },

    internalSchedule() {
      return this.schedule.internal == 1 ? 'Sim' : 'Não';
    },

    canceledBy() {
      const people_canceled = {
        0: 'advogado',
        1: 'cliente',
        null: 'sistema',
      };

      return people_canceled[this.schedule.canceled_by ?? null];
    },

    labelTime() {
      let time =
        this.schedule.time > 60 ? this.schedule.time / 60 : this.schedule.time;

      return time <= 60 ? `${time} min` : `${time} hora(s)`;
    },
  },

  methods: {
    isChargeRefunded(charge) {
      if (!charge) return false;

      return (
        charge.status == ChargeStatusEnum.REFUNDED ||
        charge.status == ChargeStatusEnum.PARTIALLY_REFUNDED
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.schedule-status {
  width: 100%;
  display: flex;
  align-items: center;

  span {
    margin-right: 10px;
    margin-top: 10px;
  }
}

.badge-status {
  padding: 6px 10px;
  &.specialty {
    background-color: var(--color-primary);
  }

  &.appointment_type {
    background-color: #5260ff;
  }
}
</style>
