<template>
  <div class="doctor-profile-view">
    <app-header> </app-header>

    <b-loading :active.sync="isLoading" :is-full-page="false"></b-loading>
    <div class="form-container">
      <form @submit.prevent="onSubmit" v-if="!isLoading">
        <div class="row">
          <div class="col-12 mb-4 alert-message" v-if="!complete">
            Por favor, complete seu cadastro.<br />
            Só poderá utilizar o sistema após completar seu cadastro.
          </div>
        </div>

        <div class="row">
          <div class="col-6">
            <b-field
              label="Foto pessoal"
              :message="errors.photo"
              class="required"
            >
              <div
                class="doctor-photo"
                :class="{ photo_invalid: !doctor.photo }"
              >
                <img :src="doctorPhoto" alt="Doctor Photo" />
                <div @click="onSelectPhotoClick" class="select-button">
                  <b-icon icon="camera" class="text-white"></b-icon>
                </div>
              </div>
            </b-field>

            <app-cropper-image
              v-if="photoToCrop"
              :src="photoToCrop"
              :aspectRatio="1"
              class="cropper-image"
              @croppedImage="onCropperImage"
            >
            </app-cropper-image>

            <input
              ref="file"
              @change="onPhotoToCropChange($event, 'profile')"
              accept="image/*"
              style="display: none"
              type="file"
              class="required"
            />
          </div>
        </div>

        <div class="row">
          <div class="col-11 mx-sm-auto col-lg-4 mx-lg-0 col-md-11">
            <app-input
              label="Nome"
              v-model="doctor.name"
              type="text"
              :errors="errors.name"
              is-required
              :disabled="true"
            ></app-input>
          </div>

          <div class="col-11 mx-sm-auto col-lg-3 mx-lg-0 col-md-11">
            <app-input
              label="CPF"
              v-model="doctor.cpf"
              type="text"
              v-mask="'###.###.###-##'"
              :errors="errors.cpf"
              is-required
              @blur="testaCPF"
              ref="cpf"
              disabled
            >
            </app-input>
          </div>

          <div class="col-11 mx-sm-auto col-lg-3 mx-lg-0 col-md-11">
            <b-field class="required" label="Gênero">
              <b-select
                class="select-custom"
                v-model="doctor.gender"
                placeholder="Selecionar"
                expanded
              >
                <option
                  v-for="gender in genders"
                  :value="gender.char"
                  :key="gender.char"
                >
                  {{ gender.name }}
                </option>
              </b-select>
            </b-field>
          </div>

          <div class="col-11 mx-sm-auto col-lg-3 mx-lg-0 col-md-11">
            <b-field class="required" label="Data de nascimento">
              <app-input
                class="input-custom"
                v-model.trim="doctor.birthday"
                placeholder="Digite a data..."
                v-mask="'##/##/####'"
                :disabled="isFieldCompletedAccount"
              ></app-input>
            </b-field>
          </div>
        </div>

        <div class="row mt-2">
          <div class="col-11 mx-sm-auto col-lg-4 mx-lg-0 col-md-11">
            <app-input
              label="E-mail"
              v-model="doctor.email"
              type="email"
              autocomplete="email"
              :errors="errors.email"
              :disabled="true"
              is-required
            ></app-input>
          </div>

          <div class="col-11 mx-sm-auto col-lg-3 mx-lg-0 col-md-11">
            <app-input
              label="Telefone"
              v-model="doctor.phone"
              type="text"
              v-mask="'(##) ####-####'"
              :errors="errors.phone"
            ></app-input>
          </div>

          <div class="col-11 mx-sm-auto col-lg-3 mx-lg-0 col-md-11">
            <app-input
              v-model="doctor.cellphone"
              label="Celular"
              type="text"
              v-mask="'(##) #####-####'"
              :errors="errors.cellphone"
              is-required
            ></app-input>
          </div>
        </div>

        <div class="row mt-2">
          <div class="col-11 mx-sm-auto col-lg-3 mx-lg-0 col-md-11">
            <tag-input
              v-model="doctor.specialties"
              :options="specialties"
              :selecteds="doctorSpecialties"
              :max="3"
              :errors="errors.specialties"
              label="Especialidade"
              is-required
            ></tag-input>
          </div>

          <div class="col-11 mx-sm-auto col-lg-3 mx-lg-0 col-md-11">
            <b-field
              label="Tipo de Atendimento"
              class="required text-danger"
              :message="errors.appointment_types"
            >
              <multiselect
                v-model="appointment_types_selected"
                :options="appointment_types"
                :multiple="true"
                :close-on-select="false"
                :clear-on-select="false"
                placeholder="Selecionar..."
                display-label="true"
                label="text"
                track-by="id"
                :searchable="false"
                :allow-empty="true"
                :preselect-first="false"
                open-direction="bottom"
                @select="onSelect"
                @remove="onRemove"
              >
                <template slot="option" slot-scope="{ option }">
                  <span class="multiselect-option" :id="option.id">
                    {{ option.name }}
                  </span>
                </template>

                <template slot="selection" slot-scope="{ values }">
                  <p class="multiselect-option m-0" v-if="values.length > 0">
                    {{ values.length }}
                    {{
                      values.length > 1
                        ? 'tipos selecionados'
                        : 'tipo selecionado'
                    }}
                  </p>
                </template>
              </multiselect>
              <b-icon
                class="toggle-question"
                icon="question-circle-fill"
                v-b-tooltip.hover="
                  'Os tipos Chat, Ligação e Vídeo Chamadas são integrados com o WhatsApp, a Ligação também integra com a chamada convencional. O cliente irá selecionar uma dessas opções disponibilizadas pelo advogado'
                "
              ></b-icon>
            </b-field>
            <b-form-text>Selecione até 3 especialidades</b-form-text>
          </div>

          <div class="col-11 mx-sm-auto col-lg-4 mx-lg-0 col-md-11">
            <div class="label">Tipos Selecionados</div>
            <app-input
              type="text"
              :value="selectedTypeAppointments"
              :readonly="true"
            ></app-input>
          </div>
        </div>

        <div class="row mt-2">
          <div class="col-sm-12 col-lg-12 col-xl-12 subtitle-edit">
            <h2>Opções da OAB</h2>
          </div>
        </div>

        <div class="row mt-2">
          <div class="col-11 mx-sm-auto col-lg-2 mx-lg-0 col-md-11">
            <app-input
              label="OAB"
              v-model="doctor.crm"
              type="text"
              :errors="errors.crm"
              is-required
              disabled
            ></app-input>
          </div>

          <div class="col-11 mx-sm-auto col-lg-3 mx-lg-0 col-md-11">
            <app-autocomplete
              label="Estado da OAB"
              v-model="doctor.crm_uf"
              :value="doctor.crm_uf"
              :getter="statesGetter"
              :setter="stateSetter"
              :errors="errors.crm_uf"
              field="name"
              is-required
              disabled
            ></app-autocomplete>
          </div>
        </div>

        <div
          :class="`row mt-4 mb-4 ${
            (doctor.crm_photo_front || doctor.crm_photo_verse) && isEditable
              ? 'docs-row'
              : ''
          }`"
        >
          <!-- col-11 mx-auto col-lg-5 mx-lg-0 col-md-11 -->
          <div class="col-11 mx-sm-auto col-lg-5 mx-lg-0 col-md-11">
            <b-field
              label="Foto da carteirinha OAB (frente) *"
              class="text-danger"
              :message="errors.crm_photo_front"
            >
              <app-file-picker
                accept="image/*"
                class="text-truncate required text-danger"
                v-model="doctor.crm_photo_front"
                @fileSelected="onCrmPhotoSelected('crm_photo_front', $event)"
                @fileDeleted="() => (this.doctor.crm_photo_front = '')"
                :disabled="true"
              >
              </app-file-picker>
            </b-field>

            <b-col
              v-if="doctor.crm_photo_front"
              class="col-12 d-flex overflow-hidden p-0"
              style="height: 170px"
            >
              <b-loading
                :active.sync="crmPhotoIsLoading"
                :is-full-page="false"
              ></b-loading>
              <b-img
                fluid
                :src="doctor.crm_photo_front"
                alt="Imagem da carteirinha OAB"
              ></b-img>
            </b-col>
          </div>

          <div class="col-11 mx-sm-auto col-lg-5 mx-lg-0 col-md-11">
            <b-field
              label="Foto da carteirinha OAB (verso) *"
              class="text-danger"
              :message="errors.crm_photo_verse"
            >
              <app-file-picker
                accept="image/*"
                class="text-truncate required text-danger"
                v-model="doctor.crm_photo_verse"
                @fileSelected="onCrmPhotoSelected('crm_photo_verse', $event)"
                @fileDeleted="() => (this.doctor.crm_photo_verse = '')"
                :disabled="true"
              >
              </app-file-picker>
            </b-field>

            <b-col
              v-if="doctor.crm_photo_verse"
              class="col-12 d-flex overflow-hidden p-0 mb-4"
              style="height: 170px"
            >
              <b-loading
                :active.sync="crmPhotoIsLoading"
                :is-full-page="false"
              ></b-loading>
              <b-img
                fluid
                :src="doctor.crm_photo_verse"
                alt="Imagem da carteirinha OAB"
              ></b-img>
            </b-col>
          </div>
        </div>

        <div class="row mt-4">
          <div class="col-11 mx-sm-auto col-lg-9 mx-lg-0">
            <b-field
              label="Mini Currículo"
              class="text-danger"
              :message="errors.mini_curriculum"
            >
              <editor
                :api-key="editorApiKey"
                :init="tinyConfig"
                v-model="doctor.mini_curriculum"
                placeholder="Escreva um Mini Currículo para clientes, mas lembre-se, é vedado o informe de quaisquer dados de contato, obrigado"
                modal-events="keydown"
              ></editor>
            </b-field>
            <b-col class="d-inline-block w-auto float-right">
              <p class="m-0 text-dark">{{ miniCurriculumLength }}/400</p>
            </b-col>
          </div>
        </div>

        <div class="row mt-2">
          <div class="col-sm-12 col-lg-12 col-xl-12 subtitle-edit">
            <h2>Endereço</h2>
          </div>
        </div>

        <div class="row mt-2">
          <div class="col-11 mx-sm-auto col-lg-3 mx-lg-0 col-md-11">
            <app-input
              label="CEP"
              type="text"
              @keyup="getCepInfo"
              v-model="doctor.cep"
              v-mask="'#####-###'"
              :errors="errors.cep"
            ></app-input>
          </div>

          <div class="col-11 mx-sm-auto col-lg-6 mx-lg-0 col-md-11">
            <app-input
              label="Rua"
              v-model="doctor.street"
              type="text"
              :errors="errors.street"
              :disabled="isLoadingCep"
              is-required
            ></app-input>
          </div>

          <div class="col-11 mx-sm-auto col-lg-3 mx-lg-0 col-md-11">
            <app-input
              label="Número"
              v-model="doctor.number"
              type="tel"
              :errors="errors.number"
              is-required
            ></app-input>
          </div>

          <div class="col-11 mx-sm-auto col-lg-3 mx-lg-0 col-md-11">
            <app-input
              label="Bairro"
              type="text"
              v-model="doctor.neighborhood"
              :errors="errors.neighborhood"
              :disabled="isLoadingCep"
              is-required
            ></app-input>
          </div>

          <div class="col-11 mx-sm-auto col-lg-3 mx-lg-0 col-md-11">
            <app-autocomplete
              class="input-custom"
              label="Cidade"
              ref="citySelector"
              v-model="doctor.city_id"
              :value="doctor.city_id"
              :getter="citiesGetter"
              :setter="citySetter"
              :errors="errors.city_id"
              field="name"
              is-required
            ></app-autocomplete>
          </div>

          <div class="col-11 mx-sm-auto col-lg-6 mx-lg-0 col-md-11">
            <app-input
              label="Complemento"
              v-model="doctor.complement"
              type="text"
              :errors="errors.complement"
            ></app-input>
          </div>
        </div>

        <div class="card" style="margin-top: 20px">
          <div class="card-header">
            <div class="card-header-icon">
              <b-icon icon="info-circle"></b-icon>
            </div>
            <div class="card-header-title">
              Os dados cadastrais deste quadro são exigidos pela legislação para
              criação de conta cobrança
            </div>
          </div>

          <div class="card-content custom-scroll">
            <div class="row mt-2">
              <div class="col-sm-12 col-lg-12 col-xl-12 subtitle-edit">
                <h2>Dados da conta cobrança</h2>
              </div>
            </div>

            <div class="row mt-2">
              <div
                class="col-11 mx-sm-auto mx-lg-0 col-lg-4 col-md-8 col-md-11"
              >
                <app-input
                  label="E-mail transacional"
                  v-model="doctor.transactional_email"
                  type="text"
                  :errors="errors.transactional_email"
                ></app-input>
              </div>
            </div>

            <div class="row mt-2">
              <div class="col-sm-12 col-lg-12 col-xl-12 subtitle-edit">
                <h2>Dados Bancário</h2>
              </div>
            </div>

            <div class="row mt-2">
              <div class="col-11 mx-sm-auto col-lg-3 mx-lg-0 col-md-11">
                <b-field
                  label="Selecionar Banco"
                  class="required text-danger"
                  :message="errors.bank"
                >
                  <b-dropdown
                    :text="selectedBankName"
                    class="dropdown-custom"
                    split-button-type="button"
                    toggle-class="dropdown-btn-custom"
                    aria-role="list"
                    boundary="scrollParent"
                    lazy
                  >
                    <template #trigger>
                      <b-button expanded icon-right="chevron-down"></b-button>
                    </template>

                    <b-dropdown-form @submit.stop.prevent>
                      <b-input
                        v-model="searchBank"
                        class="input-custom mt-2"
                        placeholder="Pesquisar por nome"
                        expanded
                      />
                    </b-dropdown-form>

                    <b-dropdown-divider></b-dropdown-divider>

                    <b-dropdown-group
                      id="list-banks"
                      class="dropdown-group-custom"
                    >
                      <b-dropdown-item-button
                        aria-role="listitem"
                        v-for="bank in filteredBanks"
                        :key="bank.id"
                        :value="bank.bank_number"
                        @click="onSelectBank(bank.bank_number)"
                      >
                        <span>{{ bank.name_with_number }}</span>
                      </b-dropdown-item-button>
                    </b-dropdown-group>
                  </b-dropdown>
                </b-field>
              </div>

              <div class="col-11 mx-sm-auto col-lg-3 mx-lg-0 col-md-11">
                <app-input
                  label="Agência"
                  v-model="doctor.agency"
                  type="text"
                  :errors="errors.agency"
                  is-required
                ></app-input>
              </div>

              <div class="col-11 mx-sm-auto col-lg-3 mx-lg-0 col-md-11">
                <app-input
                  label="Digito verificador da agência"
                  v-model="doctor.check_agency"
                  type="text"
                  v-mask="'#'"
                  :errors="errors.check_agency"
                ></app-input>
              </div>

              <div class="col-11 mx-sm-auto col-lg-3 mx-lg-0 col-md-11">
                <app-input
                  label="Conta"
                  v-model="doctor.account"
                  type="text"
                  :errors="errors.account"
                  is-required
                  v-mask="'##########'"
                ></app-input>
              </div>

              <div class="col-11 mx-sm-auto col-lg-3 mx-lg-0 col-md-11">
                <app-input
                  label="Digito verificador da conta"
                  v-model="doctor.check_account"
                  type="text"
                  v-mask="'##'"
                  :errors="errors.check_account"
                  is-required
                ></app-input>
              </div>

              <div
                v-if="showAccountComplementNumber"
                class="col-11 mx-sm-auto col-lg-3 mx-lg-0 col-md-11"
              >
                <app-input
                  label="Complemento da conta"
                  v-model="doctor.accountComplementNumber"
                  type="text"
                  :errors="errors.accountComplementNumber"
                  @keydown="onKeyDownAccountComplementNumber"
                ></app-input>
                <b-form-text>
                  Exclusivo e obrigatório apenas para contas Caixa.
                </b-form-text>
              </div>

              <div class="col-12">
                <span class="alert">
                  Obs: Utilizar uma conta bancária que esteja vinculada ao CPF!
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-5">
          <b-col class="col-lg-3 mx-lg-0 mx-md-auto button-container">
            <b-button
              type="submit"
              :loading="isLoading"
              :disabled="isLoading"
              class="button-rounded remove-focus btn-success border-0 px-3"
              block
            >
              Salvar
            </b-button>
          </b-col>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';

import * as moment from 'moment';
import { mapGetters } from 'vuex';
import debounce from 'lodash/debounce';
import Editor from '@tinymce/tinymce-vue';

import { AUTH_TOKEN } from '@/store/actions/auth';

import Autocomplete from '@/components/inputs/Autocomplete.vue';
import CropperImage from '@/components/CropperImage.vue';
import TagInput from '@/components/inputs/TagInput.vue';
import MASKS from '@/constants/masks.constant';
import PagarmeAccountStatus from '@/constants/pagarme_account_status.constant';
import DoctorAccountStage from '@/enums/DoctorAccountStage';
import SpecialtiesService from '@/services/specialties.service';

import StatesService from '@/services/states.service';
import CitiesService from '@/services/cities.service';
import BanksService from '@/services/banks.service';
import CepService from '@/services/cep.service';
import DoctorService from '@/services/doctor.service';
import StateIBGE from '@/services/state.ibge.service';
import FileMixin from '@/mixins/FileMixin';

export default {
  components: {
    'app-autocomplete': Autocomplete,
    'tag-input': TagInput,
    'app-cropper-image': CropperImage,
    multiselect: Multiselect,
    editor: Editor,
  },

  mixins: [FileMixin],

  props: {
    isModal: Boolean,
  },

  data: () => ({
    editorApiKey: process.env.VUE_APP_TINY_MCE_KEY,
    tinyConfig: {
      menubar: false,
      plugins: ['link image preview'],
      toolbar: 'undo redo | bold italic',
    },

    MASKS,
    isLoading: false,
    isLoadingCep: false,
    isFieldDisabled: false,
    isFieldCompletedAccount: false,
    crmPhotoIsLoading: false,
    documentPhotoIsLoading: false,
    selfieDocumentIsLoading: false,
    showAccountComplementNumber: false,
    photoIsLoading: false,
    photoToCrop: '',
    complete: false,
    specialties: [],
    doctorSpecialties: [],
    doctorId: null,
    errors: {},
    banks: [],
    maxDigitAgency: 4,
    searchBank: '',
    appointment_types_selected: [],
    appointment_types: [
      { id: 1, name: '1 - Chat (Whatsapp)' },
      { id: 2, name: '2 - Ligação' },
      { id: 3, name: '3 - Vídeo Chamada (Whatsapp)' },
    ],

    genders: [
      { char: 'm', name: 'Masculino' },
      { char: 'f', name: 'Feminino' },
    ],

    doctor: {
      name: null,
      email: null,
      cpf: null,
      birthday: [],
      photo: null,
      phone: null,
      cellphone: null,
      mini_curriculum: null,
      crm: null,
      crm_uf: null,
      crm_photo_front: null,
      crm_photo_verse: null,
      cep: null,
      street: null,
      number: null,
      neighborhood: null,
      complement: null,
      city_id: null,
      specialties: [],
      appointment_types: [],
      transactional_email: null,
      juno_documents_status: {
        type: null,
        status: null,
      },
      bank: null,
      agency: null,
      account: null,
      accountComplementNumber: null,
      check_agency: null,
      check_account: null,
      photoSelected: false,
      crmPhotoSelected: false,
      documentPhotoSelected: false,
      selfiePhotoSelected: false,
    },
  }),

  beforeMount() {
    this.isLoading = true;
    this.doctorId = this.$store.getters.doctorId;

    this.loadDoctorInfo();
    this.loadSpecialties();
    this.loadBanks();
  },

  watch: {
    'doctor.specialties'() {
      if (this.doctor.specialties)
        SpecialtiesService.getSpecialties(this.doctor.specialties).then();
    },

    'doctor.bank'() {
      const banksMaxDigit = ['001'];

      if (this.doctor.bank !== 104) this.showAccountComplementNumber = true;
      else this.showAccountComplementNumber = false;

      this.maxDigitAgency = banksMaxDigit.includes(this.doctor.bank) ? 5 : 4;
    },
  },

  computed: {
    ...mapGetters(['accountStage', 'pagarmeAccountStatus']),
    bankSelected() {
      let bank = this.banks.find((bank) => {
        if (this.doctor.bank == bank.bank_number) return bank;
      });

      return bank ? bank.name_with_number : 'Escolha um banco';
    },

    doctorPhoto() {
      return this.doctor.photo ?? require('@/assets/img/user.png');
    },

    isEditable() {
      return this.accountStage === DoctorAccountStage.INCOMPLETE ? false : true;
    },

    filteredBanks() {
      return this.banks.filter(
        (bank) =>
          bank.name.toLowerCase().indexOf(this.searchBank.toLowerCase()) >= 0 ||
          String(bank.bank_number).indexOf(this.searchBank) >= 0
      );
    },

    selectedBankName() {
      let bankName = this.banks.find(
        (bank) => bank.bank_number == this.doctor.bank
      )?.name;

      bankName =
        bankName && bankName.length > 15
          ? bankName.substring(0, 15) + '...'
          : bankName;

      return bankName ? bankName : 'Escolha um banco';
    },

    selectedTypeAppointments() {
      return this.appointment_types_selected
        .map((x) => x.name.replaceAll(/[-\d]/g, ''))
        .join(', ');
    },

    miniCurriculumLength() {
      const length = this.doctor.mini_curriculum?.length;
      return length > 0 ? length.toString() : '0';
    },
  },

  methods: {
    statesGetter: StatesService.search,
    citiesGetter: CitiesService.search,
    banksGetter: BanksService.search,
    banksSetter: BanksService.getBank,
    citySetter: CitiesService.getId,
    stateSetter: StatesService.getId,

    loadDoctorInfo() {
      DoctorService.getId(this.doctorId)
        .then(({ data: doctor }) => {
          this.isFieldCompletedAccount =
            doctor.account_stage == DoctorAccountStage.COMPLETE;
          this.isFieldDisabled =
            doctor.pagarme_account_status == PagarmeAccountStatus.ACTIVE &&
            doctor.account_stage == DoctorAccountStage.COMPLETE;

          this.doctor = {
            ...doctor,
            email: doctor.user.email,
            cellphone: doctor.cellphone,
            city: { name: !doctor.city ? '' : doctor.city.name },
            birthday: moment(doctor.birthday).format('DD/MM/YYYY'),
            specialties: doctor.specialties.map((specialty) => specialty.id),
          };

          this.complete = doctor.account_stage === DoctorAccountStage.COMPLETE;
          this.doctorSpecialties = doctor.specialties;
          this.appointment_types_selected = doctor.appointment_types.map(
            ({ id, name }) => ({ id, name })
          );
          this.doctor.appointment_types = doctor.appointment_types.map(
            ({ id }) => id
          );
        })
        .finally(() => (this.isLoading = false));
    },

    loadSpecialties() {
      SpecialtiesService.getSpecialties().then(({ data }) => {
        this.specialties = data;
      });
    },

    loadBanks() {
      BanksService.search().then(({ data }) => {
        this.banks = data;
      });
    },

    getCepInfo: debounce(function (event) {
      let cep = event.target?.value?.replace(/\D/g, '');

      if (['', undefined, null].includes(cep) || cep?.length < 8) return;

      this.isLoadingCep = true;

      CepService.getCepInfo(cep)
        .then(async ({ data }) => {
          let uf;

          if (data?.uf) {
            uf = await StateIBGE.get(`/estados/${data.uf}`, {
              orderBy: 'nome',
            });
          }

          this.doctor = {
            ...this.doctor,
            street: data.logradouro,
            neighborhood: data.bairro,
            state_name: uf.data?.nome,
          };
        })
        .catch(({ status }) => {
          if (status != 200) {
            this.$buefy.snackbar.open('Cep não localizado');
          }
        })
        .finally(() => (this.isLoadingCep = false));
    }, 300),

    onSelect(selected) {
      if (selected) {
        this.doctor.appointment_types.push(selected.id);
      }
    },

    onRemove(selected) {
      if (selected) {
        this.appointment_types_selected =
          this.appointment_types_selected.filter((x) => x.id !== selected.id);

        this.doctor.appointment_types = this.doctor.appointment_types.filter(
          (x) => x !== selected.id
        );
      }
    },

    onSubmit() {
      const data = {
        ...this.doctor,
        crm: this.doctor.crm ? this.doctor.crm.replace(/[\D]+/g, '') : null,
        cpf: this.doctor.cpf ? this.doctor.cpf.replace(/[\D]+/g, '') : null,
        cep: this.doctor.cep ? this.doctor.cep.replace(/[\D]+/g, '') : null,
        phone: this.doctor.phone
          ? this.doctor.phone.replace(/[\D]+/g, '')
          : null,
        cellphone: this.doctor.cellphone
          ? this.doctor.cellphone.replace(/[\D]+/g, '')
          : null,
        birthday: moment(
          this.doctor.birthday.split('/').reverse().join('-')
        ).toISOString(),
        bank: this.doctor.bank,
        agency: this.doctor.agency
          ? this.doctor.agency.replace(/[\D]+/g, '')
          : null,
        account: this.doctor.account
          ? this.doctor.account.replace(/[\D]+/g, '')
          : null,
        accountComplementNumber: this.doctor.accountComplementNumber,
        account_stage: DoctorAccountStage.COMPLETE,
      };

      if (this.doctor.photo && this.photoSelected) {
        data['photo'] = this.doctor.photo;
      }

      if (this.doctor.crm_photo_front) {
        data['crm_photo_front'] = this.doctor.crm_photo_front;
      }

      if (this.doctor.crm_photo_verse) {
        data['crm_photo_verse'] = this.doctor.crm_photo_verse;
      }

      delete data['on_line'];

      this.errors = {};
      this.isLoading = true;

      DoctorService.put(data, this.doctorId)
        .then(({ data }) => {
          let message = '';
          this.doctorSpecialties = data.specialties;
          if (!this.complete) {
            message = 'Cadastro completado com sucesso!';

            this.$store.dispatch(AUTH_TOKEN);
            setTimeout(() => this.$router.push('/schedule'), 2200);
          } else {
            message = 'Dados atualizado com sucesso!';
          }

          this.$buefy.snackbar.open({
            message,
            duration: 5000,
            pauseOnHover: true,
          });
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
          let message = '';

          if (error?.status === 420) {
            message = error.data?.errors?.message;
          } else {
            message = [422, 400].includes(error?.status)
              ? 'Verificar campos não cadastrados ou inválidos!'
              : 'Erro ao atualizar dados!';

            this.errors = error.data.errors;
          }

          return this.$buefy.snackbar.open({
            message,
            type: 'is-danger',
            duration: 5000,
            pauseOnHover: true,
          });
        })
        .finally(() => (this.isLoading = false));
    },

    onFileDelete() {
      this.doctor.photo = '';
    },

    onCrmPhotoSelected(key, value) {
      this.crmPhotoIsLoading = true;
      this.fileToBase64(value).then((base64) => (this.doctor[key] = base64));
      this.crmPhotoSelected = true;
      this.crmPhotoIsLoading = false;
    },

    onCropperImage(value) {
      this.photoIsLoading = true;
      this.fileToBase64(value).then((base64) => (this.doctor.photo = base64));
      this.photoSelected = true;
      this.photoToCrop = '';
      this.photoIsLoading = false;
    },

    async onPhotoToCropChange(event, flag) {
      const [file] = event.target.files;

      if (flag == 'profile') {
        this.photoToCrop = await this.fileToBase64(file);
      }
    },

    onSelectPhotoClick() {
      if (this.photoToCrop) this.photoToCrop = null;
      this.$refs.file.click();
    },

    onSelectBank(value) {
      this.doctor.bank = value;
    },

    onFormatMoney(event) {
      const keyValid = [44, 46, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57];

      if (!event.target?.value?.trim() && !keyValid.includes(event.charCode))
        return event.preventDefault();

      let value = event.target.value.replace(/[\D]+/g, '');

      value = value.replace(
        /(^[0-9]{1,2})*?(([0-9]{3})+)([0-9]{2}$)/g,
        (_, ...pN) => {
          pN.pop();
          pN.pop();

          pN = pN.filter((x) => x);
          const lastPN = pN.pop();
          pN.pop();
          const firstPN =
            pN.length >= 2 && pN.length - (1 % 3) != 0 ? `${pN.shift()}.` : '';

          pN = pN[0]?.replace(/([0-9]{3}(?=[0-9]{3}))/g, '$1.') || '';

          return `${firstPN}${pN},${lastPN}`;
        }
      );

      event.target.value = `R$ ${value}`;
    },

    onFormatClearMoney(event) {
      if (!event.target?.value?.trim()) return;

      event.target.value = event.target.value
        .trim()
        .replace('R$', '')
        .replaceAll('.', '');
    },

    onKeyDownAccountComplementNumber(event) {
      const validKeyCodes = [8, 37, 39];
      !validKeyCodes.includes(event.keyCode) &&
        event.target.value?.length >= this.maxDigitAgency &&
        event.preventDefault();
    },

    isAccountPagarmeCompleted() {
      if (!this.pagarmeAccountStatus) return false;

      return this.pagarmeAccountStatus === PagarmeAccountStatus.ACTIVE;
    },

    testaCPF() {
      let cpf = this.doctor.cpf.replace(/[^\d]+/g, '').trim();

      if (cpf?.length < 11) return;

      const regex = new RegExp(
        `^(1{11}|2{11}|3{11}|4{1}|5{11}|6{11}|7{11}|8{11}|9{11})$`
      );

      if (cpf?.length === 11 && regex.test(cpf)) {
        this.$buefy.snackbar.open({
          message: 'Cpf inválido',
        });
        this.doctor.cpf = '';
        return;
      }
    },

    unmask(value) {
      if (!value) return value;

      value = parseFloat(
        value.trim().replace('R$', '').replace('.', '').replace(',', '.')
      );

      return !isNaN(value) ? value : 0;
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
.alert-message {
  color: var(--color-danger);
}

.alert {
  color: var(--color-secondary);
  font-style: italic;
}

.subtitle-edit {
  display: flex;

  h2 {
    font-size: 1.2rem;
    font-weight: 600;
    color: var(--color-light);
    margin-right: 10px;
  }
}

.form-container {
  width: 100%;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 4px 10px -4px lightgrey;
  padding: 24px;

  .doctor-photo {
    width: 130px;
    height: 130px;
    border-radius: 100%;

    &.photo_invalid {
      border: 2px solid #ff3838;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
    }

    .select-button {
      width: 35px;
      height: 35px;

      border: 2px solid #fff;
      border-radius: 50%;

      display: flex;
      align-items: center;
      justify-content: center;

      background: var(--color-secondary);

      position: relative;
      top: -45px;
      left: 95px;

      cursor: pointer;
    }
  }

  .crm-preview {
    position: relative;
    width: 250px;
    height: 150px;
    margin-top: 10px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      border-radius: 8px;
    }
  }

  .document-preview {
    margin-top: 15px;
  }
}

form {
  .row:not(:first-child) > div[class^='col-'] {
    margin-top: 12px;
  }

  .button-container {
    display: flex;
    justify-content: flex-end;
  }
}

.multiselect-option {
  font-size: 1rem;
}

.textarea-custom {
  height: 150px;
  font-size: 18px;
}

.dropdown-custom {
  width: 100%;

  & .dropdown-btn-custom {
    background-color: transparent !important;
  }
}

#list-banks {
  min-width: 350px !important;
  max-height: 300px !important;
  overflow-y: auto;
}

@media only screen and (max-width: 1280px) {
  .form-container {
    .picture-preview {
      width: 160px;
    }
    .crm-preview {
      width: 200px;
    }
  }
}
</style>
