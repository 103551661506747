import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import ToggleButton from 'vue-js-toggle-button'
import VueMask from 'v-mask'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';

import CustomError from './mixins/CustomError.js';

import './config/AppComponentsConfig';
import './config/AppDirectivesConfig';
import './config/AppFiltersConfig';
import './config/FontAwesomeConfig';
import './config/MomentConfig';
import './config/BuefyConfig';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

Vue.config.productionTip = false;
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueMask);
Vue.use(ToggleButton);

new Vue({
  router,
  store,
  mixins: [CustomError],
  render: (h) => h(App),
}).$mount('#app');
