<template>
  <section class="login-page">
    <div class="login-page__info-container d-none d-lg-flex">
      <div class="info-text">
        <p>
          <b>Bem-vindo à área de login do AdvEasy,</b>
          uma plataforma para consultoria jurídica descomplicada
        </p>
        <router-link :to="{ name: 'register' }">
          <b-button type="is-text" class="mt-2 register-action">
            Criar conta
          </b-button>
        </router-link>
      </div>
    </div>

    <div
      class="login-page__form-container d-flex justify-content-center align-items-center"
    >
      <div class="form">
        <div class="container-logo col-12 col-md-11 mx-0 mx-md-auto mb-4">
          <b-img
            :src="require('@/assets/img/logo_oficial.png')"
            alt="logo Adv Easy com cores de tonalidades mais fortes voltadas para um marron e um azul escuro"
          />
        </div>

        <div class="header">
          <div v-if="!isPasswordReset">
            <h1>LOGIN</h1>
            <p>Faça login com seu email e senha</p>
          </div>

          <div v-if="isPasswordReset">
            <h1>Esqueci minha senha</h1>
            <p>
              Será enviado um e-mail para o endereço inserido com os próximos
              passos
            </p>
          </div>
        </div>

        <form v-if="!isPasswordReset" @submit.prevent="login">
          <b-field>
            <app-input
              v-model.trim="email"
              custom-class="center-text"
              placeholder="E-mail"
              type="email"
              autocomplete="false"
            ></app-input>
          </b-field>

          <b-field>
            <app-input
              v-model.trim="password"
              custom-class="center-text"
              placeholder="Senha"
              type="password"
            ></app-input>
          </b-field>

          <div class="actions">
            <b-col class="col-12 mt-5 p-0">
              <b-button
                :loading="isLoading"
                :disabled="isLoading"
                type="submit"
                class="button-primary button-rounded login-button"
                block
              >
                <b-spinner
                  v-if="isLoading"
                  class="mx-auto"
                  label="Spinning"
                  small
                ></b-spinner>
                <span v-else>Entrar</span>
              </b-button>
            </b-col>

            <a
              @click="isPasswordReset = !isPasswordReset"
              class="recovery-action"
            >
              Esqueceu sua senha? Clique aqui
            </a>

            <router-link
              :to="{ name: 'register' }"
              class="form-register-action d-block d-lg-none mx-auto mt-4"
              style="color: var(--color-primary)"
            >
              Quero me cadastrar
            </router-link>
          </div>
        </form>

        <form v-if="isPasswordReset" @submit.prevent="requestPasswordReset">
          <b-field>
            <b-input
              v-model="email"
              custom-class="center-text"
              placeholder="E-mail"
              type="email"
              autocomplete="false"
            ></b-input>
          </b-field>

          <div class="actions">
            <b-col class="col-12 mt-5 p-0">
              <b-button
                :loading="isLoading"
                :disabled="isLoading"
                native-type="submit"
                type="is-primary"
                class="button-primary button-rounded login-button"
                block
              >
                Enviar
              </b-button>
            </b-col>

            <a
              @click="isPasswordReset = !isPasswordReset"
              class="recovery-action"
            >
              Fazer Login
            </a>
          </div>
        </form>
      </div>
    </div>

    <b-modal
      v-model="showTerm"
      :title="term.name"
      size="lg"
      hide-header-close
      no-close-on-esc
      no-close-on-backdrop
      hide-backdrop
    >
      <template #modal-header>
        <b-col class="b-modal-header">
          <h1>{{ term.name }}</h1>
        </b-col>
      </template>

      <b-col class="b-modal-contents col-11 mx-auto" v-html="term.term"></b-col>

      <template class="modal-footer" #modal-footer>
        <b-col class="col-12 modal-buttons">
          <b-button class="modal-cancel" @click="onAbortTerm">Recusar</b-button>
          <b-button class="modal-accept" @click="onAcceptTerm"
            >Aceitar</b-button
          >
        </b-col>
      </template>
    </b-modal>
  </section>
</template>

<script>
import { AUTH_REQUEST, AUTH_LOGOUT } from '@/store/actions/auth';
import jwt_decode from 'jwt-decode';
import { mapGetters } from 'vuex';

import DoctorAccountStage from '@/enums/DoctorAccountStage';
import UserService from '@/services/user.service';
import TermService from '@/services/terms.service';

let vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty('--vh', `${vh}px`);

export default {
  data: () => ({
    email: null,
    password: null,
    isLoading: false,
    isPasswordReset: false,
    showTerm: false,
    term: {},
    token: null,
  }),
  computed: {
    ...mapGetters([
      'readOnly',
      'accountStage',
      'complete',
      'termUser',
      'doctorId',
    ]),
  },
  methods: {
    login() {
      const { email, password } = this;

      if (!email|| !password) return;

      this.isLoading = true;

      this.$store
        .dispatch(AUTH_REQUEST, { email, password })
        .then(async (token) => {
          this.token = token;

          if (!this.termUser?.updated) {
            const { data } = await TermService.getTerm();

            this.term = data;
            this.showTerm = true;
            return;
          }

          this.redirect();
        })
        .catch(({ status, data }) => {
          let messageNotify =
            status === 401 && data
              ? data.message
              : 'Erro no servidor, por favor tente mais tarde!';

          this.$buefy.snackbar.open({
            message: messageNotify,
            type: 'is-danger',
            position: 'is-bottom',
            duration: 5000,
            pauseOnHover: true,
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    onAbortTerm() {
      this.$store.dispatch(AUTH_LOGOUT);
      this.showTerm = false;
    },

    onAcceptTerm() {
      this.showTerm = false;

      TermService.acceptTerm(this.doctorId, {
        term: this.term.id,
      })
        .then(() => this.redirect())
        .catch(() => this.$store.dispatch(AUTH_LOGOUT));
    },

    redirect() {
      if (!this.token) return;

      const { secretary_id, permissions, account_stage } = jwt_decode(
        this.token
      );

      if (secretary_id) {
        const menu_order = ['schedule', 'schedules.index', 'patients.index'];
        let to_push = '';

        menu_order.forEach(function (menu) {
          if (permissions.indexOf(menu) > -1 && to_push == '') {
            to_push = menu.replace('.index', '');
          }
        });

        to_push != ''
          ? this.$router.push('/' + to_push)
          : this.$router.push('/secretary-profile');
      } else {
        const name =
          this.readOnly || account_stage === DoctorAccountStage.INCOMPLETE
            ? 'doctor-profile'
            : 'schedule';

        this.$router.push({ name });
      }
    },

    requestPasswordReset() {
      const { email } = this;

      if (!email?.trim()) return;

      this.isLoading = true;

      UserService.requestPasswordReset(email)
        .then(({ data }) => {
          this.$buefy.snackbar.open({
            message: data.message,
            type: 'is-success',
            position: 'is-bottom',
          });
          this.isPasswordReset = !this.isPasswordReset;
        })
        .catch(({ response: { data } }) => {
          const { message } = data;
          this.$buefy.snackbar.open({
            message,
            type: 'is-danger',
            position: 'is-bottom',
            duration: 5000,
            pauseOnHover: true,
          });
        })
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>

<style lang="scss" scoped>
.login-page {
  background: #fff;

  min-height: 100vh;
  overflow: hidden;

  &__info-container {
    display: flex;
    align-items: flex-start;

    padding: 2%;

    .info-text {
      p {
        font-size: 1.5rem;
        color: #fff;
      }
    }

    .info-action {
      position: relative;
      top: 23vh;
    }
  }

  &__form-container {
    min-height: 100vh;

    .container-logo {
      height: 150px;

      & img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .header {
      margin-bottom: 30px;
      text-align: center;
      color: #3e566c;

      h1 {
        font-size: 1.6rem;
        font-weight: 700;
        margin-bottom: 10px;
      }
      p {
        font-family: serif;
        font-size: 0.9rem;
        color: #717a83;
      }
    }

    font-family: 'Poppins';

    .actions {
      margin-top: 15px;
      text-align: center;

      button {
        margin-bottom: 20px;
      }

      .recovery-action {
        color: #a09065;
        font-size: 0.9rem;
        text-decoration: none;
      }
    }
  }
}

@mixin active-modal-button($size, $type, $color) {
  box-shadow: none !important;
  border: $size $type $color !important;
  background-color: $color !important;
  color: vr(--color-white) !important;
  filter: opacity(0.8);
}

.b-modal-header {
  h1 {
    font-size: 1.5rem;
    font-weight: 600;
    text-align: center;
    color: #3a3a3a;
    margin: 0;
  }
}

.modal-buttons {
  display: flex;
  justify-content: space-evenly;

  & .modal-cancel {
    background-color: transparent;
    border: 1px solid var(--color-danger);
    color: var(--color-danger);

    &:active,
    &:focus {
      @include active-modal-button(1px, solid, var(--color-danger));
    }
  }

  & .modal-accept {
    background-color: transparent;
    border: 1px solid var(--color-primary);
    color: var(--color-primary);

    &:active,
    &:focus {
      @include active-modal-button(1px, solid, var(--color-primary));
    }
  }
}

@media screen and (max-width: 768px) {
  * {
    font-size: 16px;
  }

  .login-page {
    display: flex;
    justify-content: center;
    position: relative;

    height: calc(var(--vh, 1vh) * 100);

    &__form-container {
      width: 80%;
      position: relative;
    }
  }
}

@media screen and (min-width: 760px) {
  .login-page {
    &::before {
      background-position: 5vw 11vh;
      background-size: 90%;
    }
  }
}

@media screen and (min-width: 800px) {
  .login-page {
    &__form-container {
      width: 80%;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

@media screen and (min-width: 1024px) {
  .login-page {
    display: flex;

    &__info-container {
      width: 70%;

      background: url('~@/assets/img/background.png');
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      background-color: #fff;

      .info-text {
        .register-action {
          float: left;

          padding: 25px 90px;

          border: 1px solid var(--color-white);

          color: #fff;

          text-decoration: none;
          background-color: transparent;

          &:active,
          &:focus,
          &:focus-visible {
            background-color: transparent !important;
            border: 1px solid var(--color-white) !important;
            box-shadow: none !important;
          }
        }
      }
    }

    &__form-container {
      width: 50%;
      padding: 3%;

      .header {
        h1 {
          font-size: 1.4rem;
        }
      }

      .actions {
        .recovery-action {
          font-size: 0.8rem;
        }
      }
    }
  }
}

@media screen and (min-width: 1200px) {
  .login-page {
    &__info-container {
      width: 75%;
      justify-content: center;

      .info-text {
        width: 80%;
        p {
          font-size: 1.8rem;
        }
      }
    }

    &__form-container {
      width: 25%;

      .header {
        h1 {
          font-size: 1.4rem;
        }
        p {
          font-size: 1rem;
        }
      }
    }
  }
}
</style>
