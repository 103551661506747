<template>
  <div class="schedule-view">
    <app-header icon="calendar" title="Agenda"></app-header>
    <section>
      <div class="card">
        <div class="card-content">
          <b-loading :active.sync="isLoading" :is-full-page="false"></b-loading>
          <div class="subtitle">
            <div class="subtitle__item">
              <div class="color" :style="{ backgroundColor: typeColors[0] }"></div>
              <p>Encerrada</p>
            </div>
            <div class="subtitle__item">
              <div class="color" :style="{ backgroundColor: typeColors[1] }"></div>
              <p>A realizar</p>
            </div>
          </div>

          <full-calendar
            ref="fullCalendar"
            v-if="!isLoading"
            :options="calendarOptions"
          ></full-calendar>
          
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import SchedulesService from '@/services/schedules.service';
import FullCalendar from '@fullcalendar/vue';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import router from '@/router';
import * as moment from 'moment';
import { mapGetters } from 'vuex';

const TYPE_COLORS = [
  '#182734',
  '#8c9296',
  '#50bbcf',
];

let currentDate = '';

export default {
  components: {
    'full-calendar': FullCalendar,
  },
  data: () => ({
    calendarOptions: {
      plugins: [dayGridPlugin, interactionPlugin],
      initialView: 'dayGridMonth',
      locale: 'pt-BR',
      buttonText: {
        today: 'Hoje',
        month: 'Mensal',
        day: 'Diário',
      },
      headerToolbar: {
        left: 'title',
        center: '',
        right: 'dayGridDay,dayGridMonth,prev,next',
      },
      eventClick: ({ event }) => router.push(`schedules/${event.id}`),
    },
    events: [],
    isLoading: false,
    date: currentDate,
    typeColors: TYPE_COLORS,
  }),
  computed: {
    ...mapGetters(['doctorId']),
  },

  methods: {
    loadSchedule() {
      this.isLoading = true;

      SchedulesService.getScheduleList(this.doctorId)
        .then(({ data }) => {
          data.map((schedule) => {
            const afterScheduleStart = moment().isAfter(moment(schedule.start));

            if (!schedule.canceled_at) {
              this.events.push({
                id: schedule.id,
                title: this.getEventName(schedule),
                start: moment(schedule.start).toDate(),
                end: moment(schedule.end).toDate(),
                allDay: true,
                textColor: afterScheduleStart ? 'white' : 'black',
                backgroundColor:
                  TYPE_COLORS[afterScheduleStart ? 0 : 1],
              });
            }
          });
          this.calendarOptions.events = this.events;
        })
        .finally(() => {
          this.isLoading = false;
          this.$emit('calendar_loaded', true);
        });
    },
    getEventName(schedule) {
      const { start, end, patient_name } = schedule;

      return `${moment(start).format('HH:mm')} - ${moment(end).format(
        'HH:mm'
      )} / ${patient_name}`;
    },
  },
  mounted() {
    this.loadSchedule();
  },
};
</script>

<style lang="scss">
.subtitle {
  margin-bottom: 8px !important;

  &__item {
    display: flex;
    align-items: center;

    .color {
      height: 12px;
      width: 12px;
      border-radius: 50%;
      margin-right: 8px;
    }

    p {
      font-size: 1rem;
    }
  }
}

.fc-toolbar-title {
  text-transform: capitalize;
}
</style>
