<template>
  <div class="weekdays-selector">
    <b-field :label="label || 'Dias da Semana'"></b-field>
    <div class="weekdays-selector__days">
      <div
        @click="handleDay(day.day)"
        v-for="day in days"
        :key="day.day"
        class="day"
        :class="{ selected: isSelected(day.day) }"
      >
        {{ day.label }}
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  props: ['label', 'model'],
  model: {
    prop: 'model',
    event: 'input',
  },
  data: () => ({
    days: moment
      .weekdays()
      .map((day, idx) => ({ day: idx, label: day.charAt(0).toUpperCase() })),
    selectedDays: [],
  }),
  methods: {
    handleDay(day) {
      const idx = this.selectedDays.indexOf(day);

      if (idx === -1) {
        this.selectedDays = [...this.selectedDays, day];
      } else {
        this.selectedDays = this.selectedDays.filter((el) => el !== day);
      }
    },
    isSelected(day) {
      return this.selectedDays.indexOf(day) !== -1;
    },
  },
  watch: {
    selectedDays() {
      this.$emit('input', this.selectedDays);
    },
  },
  mounted() {
    this.selectedDays = this.model || [];
  },
};
</script>

<style lang="scss" scoped>
.weekdays-selector {
  &__days {
    display: flex;
    align-items: center;

    .day {
      padding: 6px 12px;
      border-radius: 4px;
      background: #f5f5f5;
      margin-right: 6px;
      cursor: pointer;

      &:not(.selected):hover {
        background: #e5e5e5;
      }

      &.selected {
        color: #fff;
        font-weight: 600;
        background: linear-gradient(290deg, #b58e1c, #d8a713);
      }
    }
  }
}
</style>
