<template>
  <div id="app">
    <loading-page v-if="isLoading"></loading-page>
    <router-view v-if="!isLoading" />

    <b-modal
      class="modal-term"
      v-model="showTerm"
      :title="term.name"
      size="lg"
      hide-header-close
      no-close-on-backdrop
      no-close-on-esc
    >
      <b-col class="b-modal-contents col-12">
        {{ term.term }}
      </b-col>

      <template class="modal-footer" #modal-footer>
        <b-col class="col-12 modal-buttons">
          <b-button class="modal-cancel" @click="onAbortTerm">Recusar</b-button>
          <b-button class="modal-accept" @click="onAcceptTerm"
            >Aceitar</b-button
          >
        </b-col>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import LoadingPage from '@/views/Loading.vue';
import { AUTH_TOKEN, AUTH_LOGOUT } from '@/store/actions/auth';
import DoctorAccountStage from '@/enums/DoctorAccountStage';

export default {
  components: {
    'loading-page': LoadingPage,
  },

    data: () => ({
    isLoading: false,
    showTerm: false,
    term: {},
  }),

  computed: {
    ...mapGetters(['accountStage', 'secretaryId', 'termUser']),
  },

  mounted() {
    this.isLoading = true;

    this.$store
      .dispatch(AUTH_TOKEN)
      .then(() => {        
        if (!this.termUser?.updated) {
          this.$store.dispatch(AUTH_LOGOUT);

          this.$route.push('/login')
        }

        if ((this.accountStage == DoctorAccountStage.INCOMPLETE) /* && !this.secretaryId */)
          this.$router.push('/doctor-profile');
        else
          this.$router.push('/schedule');
      })
     .catch(() => this.$route.name != "login" && this.$router.push("/login"))
      .finally(() => (this.isLoading = false));
  },
};
</script>

<style lang="scss">
@import 'styles/_global.scss';

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--text-color);
}
</style>
